<script>
import Vue from "vue";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import StarRating from "vue-star-rating";
import VueEasyLightbox from "vue-easy-lightbox";
import { apiRequest } from "@/helpers/api-call";
import Multiselect from "vue-multiselect";
import { format } from "date-fns";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  required,
  email,
  minLength,
  sameAs,
  maxLength,
  minValue,
  maxValue,
  numeric,
  url,
  alphaNum,
} from "vuelidate/lib/validators";
import ChartYear from "./ChartYear.vue"
import ChartMonth from "./ChartMonth.vue"
import ChartDay from "./ChartDay.vue"
const yearTable = [];
for (let i = 2024; i <= 2050; i++) {
  yearTable.push({ label: `${i}`, value: i })
}
/**
 * Form validation component
 */
export default {
  page: {
    title: "Information utilisateur",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    StarRating,
    VueEasyLightbox,
    ckeditor: CKEditor.component,
    Multiselect,
    ChartYear,
    ChartMonth,
    ChartDay,
  },
  data() {
    return {
      title: "Ajouter utilisateur",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Ajouter",
          active: true,
        },
      ],
      selectedMonth: 4,
      yearList: yearTable,
      selectedYearStat: 2024,
      selectedOption: 1,
      optionsList: [
        { key: 1, label: "Performance sur revenus (%)" },
        { key: 2, label: "Demandes acceptées" },
        { key: 3, label: "Distance totale" },
        { key: 4, label: "Courses précises" },
        { key: 5, label: "Taux d'acceptation" },
        { key: 6, label: "Temps total en ligne" },
        { key: 7, label: "Total courses effectuées" },
        { key: 8, label: "Total revenu" },
        { key: 9, label: "Revenu moyen" },
        { key: 10, label: "Total demandes", },
      ],
      monthList: [
        { key: 1, label: "Janvier" },
        { key: 2, label: "Février" },
        { key: 3, label: "Mars" },
        { key: 4, label: "Avril" },
        { key: 5, label: "Mai" },
        { key: 6, label: "Juin" },
        { key: 7, label: "Juillet" },
        { key: 8, label: "Août" },
        { key: 9, label: "Septembre" },
        { key: 11, label: "Octobre", },
        { key: 12, label: "Novembre", },
        { key: 1, label: "Decembre", },
      ],
      addNewVehicle: false,
      ordersData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      conducteur: [
        { key: "trajet", sortable: true, label: "Trajet" },
        { key: "date", sortable: true, label: "Départ" },
        { key: "prix", sortable: true, label: "Prix" },
        { key: "passagers", sortable: true, label: "Passagers" },
        { key: "statut", sortable: true, label: "Statut" },
      ],
      fields: [
        { key: "trajet", sortable: true, label: "Trajet" },
        { key: "date", sortable: true, label: "Départ" },
        { key: "prix", sortable: true, label: "Prix" },
        { key: "passager", sortable: true, label: "Passagers" },
        { key: "statut", sortable: true, label: "Statut" },
      ],
      fieldsTaxi: [
        { key: "passager", sortable: true, label: "Passager" },
        { key: "telephone", sortable: true, label: "Téléphone" },
        { key: "type", sortable: true, label: "Type de course" },
        { key: "montant", sortable: true, label: "Prix client " },
        { key: "montantDriver", sortable: true, label: "Prix Asso " },
        { key: "commission", sortable: true, label: "Commission " },
        { key: "heureDepart", sortable: true, label: "Départ" },
        { key: "heureArrivee", sortable: true, label: "Arrivée" },
        { key: "debutAttente", sortable: true, label: "Début d'attente" },
        { key: "attente", sortable: true, label: "Temps d'attente" },
        { key: "distance", sortable: true, label: "Distance en Km" },
      ],
      fieldsTaxiP: [
        { key: "conducteur", sortable: true, label: "Conducteur" },
        { key: "telephone", sortable: true, label: "Téléphone" },
        { key: "type", sortable: true, label: "Type de course" },
        { key: "montant", sortable: true, label: "Prix client " },
        { key: "montantDriver", sortable: true, label: "Prix Asso " },
        { key: "commission", sortable: true, label: "Commission " },
        { key: "heureDepart", sortable: true, label: "Départ" },
        { key: "heureArrivee", sortable: true, label: "Arrivée" },
        { key: "debutAttente", sortable: true, label: "Début d'attente" },
        { key: "attente", sortable: true, label: "Temps d'attente" },
        { key: "distance", sortable: true, label: "Distance en Km" },
      ],
      passager: [
        { key: "trajet", sortable: true, label: "Trajet" },
        { key: "date", sortable: true, label: "Départ" },
        { key: "prix", sortable: true, label: "Prix" },
        { key: "passagers", sortable: true, label: "Passagers" },
        { key: "statut", sortable: true, label: "Statut" },
      ],
      permisObject: null,
      currentPageTaxi: 1,
      perPageTaxi: 10,
      taxiData: [],
      currentPageTaxiP: 1,
      perPageTaxiP: 10,
      taxiDataP: [],
      tokenUser: JSON.parse(localStorage.getItem("user")).tokenSalt,
      idUser: 0,
      fisrtname: "",
      lastname: "",
      apropos: "",
      genre: "",
      boite_postale: "",
      profession: "",
      birthday: "",
      adress: "",
      city: "",
      country: "",
      number_phone: "",
      email: "",
      password: "",
      showPassword: false,
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      index: 0,
      AutoMoto: {
        type: "",
        climatisation: "",
        anneeCirculation: "",
        categorie: "",
        couleur: "",
        immatriculation: "",
        model: "",
        motorisation: "",
        nomAuto: "",
        cartegrise: "",
        nombresiege: "",
        clime: false,
        id: null
      },
      showModalRetrait: false,
      showModalCredit: false,
      fileProfil: null,
      filePermis: null,
      fileMoto: null,
      fileCni: null,
      user: {
        username: "",
        usertype: "",
        tel: "",
        email: "",
        levelRate: "",
        level: "",
        rating: "",
        nombreVote: "",
        active: "",
        badge: "",
        solde: 0,
        villeresidence: "",
        apropos: "",
        villeHabite: "",
        dateInscription: null,
        dateNaissance: null,
        latWorkPlace: 0.0,
        lngWorkPlace: 0.0,
        latitude: 0.0,
        longitude: 0.0,
        personneUrgence: "",
        contactUrgence: "",
        indicatifContactTel: ""
      },
      pref: {
        user: 0,
        enjoyMusicInCar: "",
        discussInCar: "",
        smokeInCar: "",
        eatInCar: "",
        acceptPeopleDisabilities: "",
        tolerateStops: "",
        userType: "",
        genre: ""
      },
      showModalPref: false,
      verify: {
        email: false,
        permis: false,
        autoMoto: false,
        image: false,
        tel: false,
        cni: false,
      },
      activity: {
        publies: 0,
        annulees: 0,
        a_venir: 0,
        effectues: 0,
        gains: 0,
        reservations_annulees: 0,
        voyages_effectues: 0,
        reservations_effectuees: 0,
      },
      allImage: {
        avatar: "",
        permis: "",
        vehicule: "",
        cni: "",
      },
      showretire: false,
      showSucess: false,
      showSucessM: false,
      showEchec: false,
      showEchecM: false,
      userDesac: null,
      avislaisses: [],
      avisrecus: [],
      conducteurData: [],
      passagerData: [],
      img: "",
      vue: false,
      visible: false,
      showModalprofil: false,
      showModalcni: false,
      showModalpermis: false,
      showModalautoMoto: false,
      HistoriqueConducteur: false,
      HistoriquePassager: false,
      picture: ["PROFIL", "CNI", "PERMIS", "VOITURE"],
      imgs: "",
      showMessage: false,
      showMessageMail: false,
      editor: ClassicEditor,
      editorData: "",
      messageSelect: null,
      sujet_message: "",
      mediaSelect: null,
      messageContent: [
        {
          sujet: "",
          message: "",
          userId: "",
        },
      ],
      url_link: "",
      userTypeSelect: "",
      tyMedia: [
        {
          name: "",
          value: 0,
        },
        {
          name: "Image",
          value: "image",
        },
        {
          name: "Vidéo",
          value: "video",
        },
      ],
      tyMessage: [
        {
          name: "Notification",
          value: 0,
        },
        {
          name: "Mail",
          value: 1,
        },
        {
          name: "Notification + Mail",
          value: 2,
        },
      ],
      showModalChangePass: false,
      showModalChangeName: false,
      showModalChangeTel: false,
      showChange: false,
      showEchecChange: false,
      newpassword: "",
      confnewpassword: "",
      showUpdate: false,
      indicatif: "",
      tel: "",
      villeresidence: "",
      list_other_vehicule: [],
      showModalChangeEmail: false,
      showModalChangeLieuTravail: false,
      adresseEmail: null,
      showModalWorkspace: false,
      showModalChangeApropo: false,
      showModalChangeBday: false,
      showModalChangeUrgence: false,
      showMessageWhatsapp: false,
      userVehiculeInfo: {
        modele: null,
        couleur: null,
        immatriculation: null,
        motorisation: null,
        climatisation: null,
        anne: null,
        siege: null,
        imageSrc: null
      },
      actualImageSrc: null,
      showModalautoMotoOtherPop: false,
      gainCurrentUser: {
        "attente": 0,
        "total": 0,
        "approuve": 0,
        "rejete": 0,
      },
      vehicules: [],
    }
  },
  validations: {
    firstname: { required },
    lastname: { required },
    genre: { required },
    number_phone: { required, numeric },
    email: { required, email },
    password: { required },
    tooltipform: {
      fname: { required },
      lname: { required },
      username: { required },
      city: { required },
      state: { required },
    },
    range: {
      minlen: { required, minLength: minLength(6) },
      maxlength: { required, maxLength: maxLength(6) },
      between: { required, minLength: minLength(5), maxLength: maxLength(10) },
      minval: { required, minValue: minValue(6) },
      maxval: { required, maxValue: maxValue(6) },
      rangeval: { required, minValue: minValue(6), maxValue: maxValue(100) },
      expr: { required },
    },
    typeform: {
      name: { required },
      password: { required, minLength: minLength(6) },
      confirmPassword: { required, sameAsPassword: sameAs("password") },
      email: { required, email },
      url: { required, url },
      digit: { required, numeric },
      number: { required, numeric },
      alphanum: { required, alphaNum },
      textarea: { required },
    },
    conducteurId: 0,
    montantToCredit: 0,
    
  },
  async mounted() {
    this.conducteurId = this.$route.params.idUser;
    this.init();
    this.loadUserGain();
    this.courseStat();
    this.courseStatP();
  },
  methods: {
    openUpdatePref() {
      console.log("m")
    },
    crediteModal() {
      this.idUser = this.$route.params.idUser;
      this.showModalCredit = true;
    },
    retraitModal() {
      this.idUser = this.$route.params.idUser;
      this.showModalRetrait = true;
    },
    async courseStatP() {
      const request = await apiRequest('GET', `courses-historique-byid?user=${this.idUser}`,)
      if (request && request.status == 200) {
        const response = request.data
        // console.log("response responseresponsestata", response);
        if (response.courses_passager.length !== 0) {
          const responseDataP = response.courses_passager.map(el => {
            if (el.debutAttente && el.dateHeureDepart) {
              let startWaiting = new Date(el.debutAttente)
              let endWaiting = new Date(el.dateHeureDepart)
              let startWaitingTimesStamp = startWaiting.getTime();
              let endWaitingTimesStamp = endWaiting.getTime();
              const elapsedTime = endWaitingTimesStamp - startWaitingTimesStamp;
              var elapsedTimeInMinute = elapsedTime / (1000 * 60)
            }
            return {
              conducteur: (el.conducteur && el.conducteur.personne) ? el.conducteur.personne.lastname + " " + el.conducteur.personne.firstname : "Aucun conducteur trouvé",
              telephone: (el.conducteur && el.conducteur.personne) ? el.conducteur.personne.indicatifTel + " " + el.conducteur.personne.tel : "Aucun conducteur trouvé",
              heureArrivee: el.dateHeureArrivee ? new Date(el.dateHeureArrivee).toLocaleDateString('FR-fr') + "  " + new Date(el.dateHeureArrivee).toLocaleTimeString('FR-fr') : "Pas de date",
              heureDepart: el.dateHeureDepart ? new Date(el.dateHeureDepart).toLocaleDateString('FR-fr') + "  " + new Date(el.dateHeureDepart).toLocaleTimeString('FR-fr') : "Pas de date",
              debutAttente: new Date(el.debutAttente).toLocaleDateString('FR-fr') + "  " + new Date(el.debutAttente).toLocaleTimeString('FR-fr'),
              attente: elapsedTimeInMinute ? Math.ceil(elapsedTimeInMinute) : "Pas d'attente",
              distance: el.distance + "Km",
              montant: el.prixFinal,
              commission: el.commission ? el.commission : " Pas de commission",
              montantDriver: el.prixConducteur ? el.prixConducteur : " Aucun prix",
              type: el.moyenTransport,
            }
          }
            //el => this.taxiPassagerData.push(el)
          );
          responseDataP.map(el => this.taxiDataP.push(el));
          console.log("passager");
        }
      }
    },
    async courseStat() {
      const request = await apiRequest('GET', `courses-historique-byid?user=${this.idUser}`,)
      if (request && request.status == 200) {
        const response = request.data
        console.log("response responseresponsestata", response);
        if (response.courses_conducteur.length !== 0) {
          const responseData = response.courses_conducteur.map(el => {
            if (el.debutAttente && el.dateHeureDepart) {
              let startWaiting = new Date(el.debutAttente)
              let endWaiting = new Date(el.dateHeureDepart)
              let startWaitingTimesStamp = startWaiting.getTime();
              let endWaitingTimesStamp = endWaiting.getTime();
              const elapsedTime = endWaitingTimesStamp - startWaitingTimesStamp;
              var elapsedTimeInMinute = elapsedTime / (1000 * 60)
            }
            return {
              passager: (el.passager && el.passager.personne) ? el.passager.personne.lastname + " " + el.passager.personne.firstname : "Zémitcha",
              telephone: (el.passager && el.passager.personne) ? el.passager.personne.indicatifTel + " " + el.passager.personne.tel : ((el.tel) ? el.indicatifTel + el.tel : "Zémitcha"),
              heureArrivee: el.dateHeureArrivee ? new Date(el.dateHeureArrivee).toLocaleDateString('FR-fr') + "  " + new Date(el.dateHeureArrivee).toLocaleTimeString('FR-fr') : "Pas de date",
              heureDepart: el.dateHeureDepart ? new Date(el.dateHeureDepart).toLocaleDateString('FR-fr') + "  " + new Date(el.dateHeureDepart).toLocaleTimeString('FR-fr') : "Pas de date",
              attente: elapsedTimeInMinute ? Math.ceil(elapsedTimeInMinute) : "Pas d'attente",
              debutAttente: new Date(el.debutAttente).toLocaleDateString('FR-fr') + "  " + new Date(el.debutAttente).toLocaleTimeString('FR-fr'),
              distance: el.distance + "Km",
              montant: el.prixFinal,
              commission: el.commission ? el.commission : " Pas de commission",
              montantDriver: el.prixConducteur ? el.prixConducteur : " Aucun prix",
              type: el.moyenTransport,
            }
          }
          )
          console.log('on est good et toutt', responseData);
          responseData.map(el => this.taxiData.push(el));
        }
      }
    },
    async credit() {
      this.showModalCredit = false;
      if (
        this.idUser == 0 ||
        this.montantToCredit == 0 ||
        this.montantToCredit < 0
      ) {
        this.showEchec = true;
      } else {
        const data = await apiRequest(
          "POST",
          "admin/wallet/wallet",
          {
            idUser: this.idUser,
            montant: this.montantToCredit,
          },
          false
        );
        if (data && data.data) {
          this.init();
          this.$toast.success("Portefeuille mis à jour avec succès !!!", {
            position: "bottom-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false
          });
        } else {
          this.$toast.error("Erreur. Veuillez réessayer.", {
            position: "bottom-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false
          });
          this.init();
        }
      }
    },
    async retrait() {
      this.showModalRetrait = false;
      console.log("idUser:", this.idUser);
      if (
        this.idUser == 0 ||
        this.montantToCredit == 0 ||
        this.montantToCredit < 0
      ) {
        this.showEchec = true;
      } else {
        const data = await apiRequest(
          "POST",
          "admin/wallet/retrait",
          {
            idUser: this.idUser,
            montant: this.montantToCredit,
          },
          false
        );
        console.log("data:", data);
        if (data && data.data) {
          this.$toast.success("Opération effectuée avec succès !!!", {
            position: "bottom-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false
          });
          this.init();
        } else {
          this.$toast.error("Opération non effectuée", {
            position: "bottom-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false
          });
          this.init();
        }
      }
    },
    async updatePref() {
      this.pref.user = this.idUser
      console.log("this pref", this.pref);
      const response = await apiRequest('PUT', 'dash-update-pref', this.pref)
      if (response && response.status == 200) {
        this.$toast.success("Préférences mis à jour avec succès !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.init();
        this.showModalPref = false;
      }
    },
    async init() {
      this.vehicules = []
      //console.log("tokenUser:", this.tokenUser);
      this.idUser = this.$route.params.idUser;
      //console.log("id:",this.idUser)
      //Chargement des données
      const data = await apiRequest(
        "POST",
        "admin/detail-user",
        { id: this.idUser },
        false
      );
      console.log("data.data:", data.data);
      if (data && data.data) {
        data.data.vehicules.map(el => {
          this.vehicules.push(el)
        });
        this.permisObject = data.data.permis ? data.data.permis : null ;
        console.log("this vehicules", this.vehicules);
        console.log("data.data:", data.data);
        this.user.dateInscription = new Date(data.data.dateCreation).toLocaleDateString("Fr-fr")
        this.user.dateNaissance = data.data.personne.birthday
        this.user.username =
          data.data.personne.lastname + " " + data.data.personne.firstname;
        this.lastname = data.data.personne.lastname;
        this.firstname = data.data.personne.firstname;
        this.list_other_vehicule = data.data.vehicules;
        this.user.usertype = data.data.userType;
        this.user.tel = data.data.personne.tel && data.data.personne.tel != "" ? data.data.personne.indicatifTel + data.data.personne.tel : "";
        this.user.email = data.data.personne.email;
        this.user.apropos = data.data.personne.apropos;
        this.user.villeHabite = data.data.personne.adresse;
        this.user.villeresidence = data.data.personne.workPlaceName;
        this.user.levelRate = data.data.levelRate;
        this.user.level = data.data.level;
        this.user.rating = data.data.rating ? data.data.rating : "0";
        this.user.nombreVote = data.data.nombreVote
          ? data.data.nombreVote
          : "0";
        this.user.indicatifContactTel = data.data.personne.indicatifContactTel
          ? data.data.personne.indicatifContactTel
          : "";
        this.user.personneUrgence = data.data.personne.personneUrgence
          ? data.data.personne.personneUrgence
          : "";
        this.user.contactUrgence = data.data.personne.contactUrgence
          ? data.data.personne.contactUrgence
          : "";
        this.user.solde = data.data.wallet.solde;
        this.indicatif =
          data.data.personne.tel && data.data.personne.tel != ""
            ? data.data.personne.indicatifTel
            : "";
        this.tel =
          data.data.personne.tel && data.data.personne.tel != ""
            ? data.data.personne.tel
            : "";
        this.user.active = data.data.active;
        this.user.badge = data.data.userValidated;
        this.verify.email = data.data.emailVerified;
        this.verify.permis = data.data.permisVerified;
        //this.verify.autoMoto = data.data.autoMotoVerified;
        this.verify.image = data.data.pictureVerified;
        this.verify.tel = data.data.telephoneVerified;
        this.verify.cni = data.data.cniVerified;
        if (data.data.preferences) {
          (this.pref.acceptPeopleDisabilities =
            data.data.preferences.acceptPeopleDisabilities),
            (this.pref.discussInCar = data.data.preferences.discussInCar),
            (this.pref.eatInCar = data.data.preferences.eatInCar),
            (this.pref.enjoyMusicInCar =
              data.data.preferences.enjoyMusicInCar),
            (this.pref.smokeInCar = data.data.preferences.smokeInCar),
            (this.pref.genre = data.data.preferences.genre),
            (this.pref.userType = data.data.preferences.userType),
            (this.pref.tolerateStops = data.data.preferences.tolerateStops);
        }
        if (data.data.userAvatarCode !== null) {
          console.log("voici le code d'avata user ", data.data.userAvatarCode)
          this.allImage.avatar =
            `https://rmobility.ovh/admin/image/?codeImage=` +
            data.data.userAvatarCode +
            `&token=` +
            this.tokenUser;
        } else {
          this.allImage.avatar = require("@/assets/images/users/attente.png");
        }
        if (data.data.userCniCode) {
          this.allImage.cni =
            `https://rmobility.ovh/admin/image/?codeImage=` +
            data.data.userCniCode +
            `&token=` +
            this.tokenUser;
        } else {
          this.allImage.cni = require("@/assets/images/users/attente.png");
        }
        if (data.data.vehicule) {
          if (
            data.data.vehicule.autoMotoPermisCode !== null &&
            this.tokenUser !== null
          ) {
            this.allImage.permis =
              `https://rmobility.ovh/admin/image/?codeImage=`+
              data.data.vehicule.autoMotoPermisCode +
              `&token=` +
              this.tokenUser;
          } else {
            this.allImage.permis = require("@/assets/images/users/attente.png");
          }
        } else {
          this.allImage.permis = require("@/assets/images/users/attente.png");
        }
        // if (data.data.vehicule) {
        //   if (data.data.vehicule.autoMotoPictureCode !== null) {
        //     // this.allImage.vehicule =
        //     //   `https://rmobility.ovh/admin/image/?codeImage=` +
        //     //   data.data.vehicule.autoMotoPictureCode +
        //     //   `&token=` +
        //     //   this.tokenUser;
        //     this.actualImageSrc = `https://rmobility.ovh/admin/image/?codeImage=` +
        //       data.data.vehicule.autoMotoPictureCode +
        //       `&token=` +
        //       this.tokenUser;
        //   } else {
        //     this.allImage.vehicule = require("@/assets/images/users/attente.png");
        //   }
        // } else {
        //   this.allImage.vehicule = require("@/assets/images/users/attente.png");
        // }
        this.list_other_vehicule.forEach(element => {
          console.log("Autre véhicule ", element);
        })
      }
      const dataActivity = await apiRequest(
        "GET",
        "admin/trajets/activites/?id=" + this.idUser,
        undefined,
        false
      );
      console.log("data.activity:", dataActivity.data);
      if (dataActivity && dataActivity.data) {
        console.log(dataActivity.data);
        this.activity.publies = dataActivity.data.conducteur.publie
          ? dataActivity.data.conducteur.publie
          : "0";
        this.activity.annulees = dataActivity.data.conducteur.annules
          ? dataActivity.data.conducteur.annules
          : "0";
        this.activity.a_venir = dataActivity.data.conducteur.avenir
          ? dataActivity.data.conducteur.avenir
          : "0";
        this.activity.effectues = dataActivity.data.conducteur.effectues
          ? dataActivity.data.conducteur.effectues
          : "0";
        this.activity.gains = dataActivity.data.conducteur.gains
          ? dataActivity.data.conducteur.gains
          : "0";
        this.activity.reservations_effectuees = dataActivity.data.passager
          .reserEffectues
          ? dataActivity.data.passager.reserEffectues
          : "0";
        this.activity.reservations_annulees = dataActivity.data.passager
          .reserAnnules
          ? dataActivity.data.passager.reserAnnules
          : "0";
        this.activity.voyages_effectues = dataActivity.data.passager
          .voyagesEffectues
          ? dataActivity.data.passager.voyagesEffectues
          : "0";
      }
      const avisData = await apiRequest(
        "GET",
        "admin/avis/?id=" + this.idUser,
        undefined,
        false
      );
      //console.log("avis:",avisData.data);
      if (avisData && avisData.data) {
        this.avislaisses = avisData.data.avisEmis;
        this.avisrecus = avisData.data.avisRecu;
      }
      const historiqueAdmin = await apiRequest(
        "GET",
        "admin/trajets/historique/?id=" + this.idUser,
        undefined,
        false
      );
      //console.log("historique admin:", historiqueAdmin.data)
      if (
        historiqueAdmin &&
        historiqueAdmin.data &&
        historiqueAdmin.data.conducteur
      ) {
        const historiqueConducteurTable = historiqueAdmin.data.conducteur.sort((a, b) => b.id - a.id).map(
          (historique) => {
            var passagers = "";
            historique.passagers.forEach((element) => {
              if (element.statut == "ACTIF") {
                passagers +=
                  "-" + element.firstname + " " + element.lastname + "\n";
              }
            });
            return {
              trajet: historique.lieuDepart + " >> " + historique.lieuArrivee,
              date: format(
                new Date(historique.dateHeureDepart),
                "dd-MM-yyyy hh:mm:ss"
              ),
              prix: historique.prix,
              statut: historique.statutTrajet,
              passagers: passagers,
            };
          }
        );
        this.conducteurData = historiqueConducteurTable;
        //this.conducteurData = historiqueConducteurTable;
        //console.log('this.conducteurData:', this.conducteurData)
      }
      if (
        historiqueAdmin &&
        historiqueAdmin.data &&
        historiqueAdmin.data.passager
      ) {
        const historiquePassagerTable = historiqueAdmin.data.passager.map(
          (historique) => {
            var passagers = "";
            historique.passagers.forEach((element) => {
              if (element.statut == "ACTIF") {
                passagers +=
                  "-" + element.firstname + " " + element.lastname + "\n";
              }
            });
            return {
              trajet: historique.lieuDepart + " >> " + historique.lieuArrivee,
              date: format(
                new Date(historique.dateHeureDepart),
                "dd-MM-yyyy hh:mm:ss"
              ),
              prix: historique.prix,
              statut: historique.statutTrajet,
              passagers: passagers,
            };
          }
        );
        this.passagerData = historiquePassagerTable;
        //console.log('this.conducteurData:', this.conducteurData)
      }
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;
      // tolerateStops here if form is invalid
      this.$v.$touch();
    },
    verification: function () {
      console.log("ok");
    },
    ValidationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    Submit: function () {
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      alert("data submit");
    },
    showImg(index) {
      this.index = index;
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    toogleShowPassword() {
      var show = document.getElementById("password");
      if (this.showPassword == false) {
        this.showPassword = true;
        show.type = "text";
      } else {
        this.showPassword = false;
        show.type = "password";
      }
    },
    tooltipForm() {
      this.submitform = true;
      this.$v.$touch();
    },
    showSingle() {
      this.imgs = require("@/assets/images/logo.png");
      this.show();
    },
    showImage(img) {
      //console.log(img.srcElement.src)
      this.imgs = img.srcElement.src;
      console.log(this.imgs);
      this.show();
    },
    show() {
      this.visible = true;
    },
    handleFileProfil() {
      this.fileProfil = this.$refs.fileProfil.files[0];
    },
    handleFilePermis() {
      this.filePermis = this.$refs.filePermis.files[0];
    },
    handleFileMoto() {
      this.fileMoto = this.$refs.fileMoto.files[0];
    },
    handleFileCni() {
      this.fileCni = this.$refs.fileCni.files[0];
    },
    async verifyPictureRefuse() {
      const verify = await apiRequest(
        "POST",
        "dash/response-profile",
        { idUser: this.idUser, reponse: 0 },
        false
      );
      console.log("verify:", verify);
      if (verify && verify.status == 200) {
        this.$toast.success("Photo de profil rejetée avec succès !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.init();
      }
    },
    async verifyPictureAccepte() {
      const verify = await apiRequest(
        "POST",
        "dash/response-profile",
        { idUser: this.idUser, reponse: 1 },
        false
      );
      console.log("verify:", verify);
      if (verify && verify.status == 200) {
        this.$toast.success("Photo de profil validée avec succès !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.init();
      }
    },
    async verifyCniRefuse() {
      const verify = await apiRequest(
        "POST",
        "dash/response-cni",
        { idUser: this.idUser, reponse: 0 },
        false
      );
      console.log("verify:", verify);
      if (verify && verify.status == 200) {
        this.$toast.success(`Carte d'identité rejetée avec succès !!!`, {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.init();
      }
    },
    async verifyCniAccepte() {
      const verify = await apiRequest(
        "POST",
        "dash/response-cni",
        { idUser: this.idUser, reponse: 1 },
        false
      );
      console.log("verify:", verify);
      if (verify && verify.status == 200) {
        this.$toast.success(`Carte d'identité validée avec succès !!!`, {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.init();
      }
    },
    async verifyPermisRefuse() {
      const verify = await apiRequest(
        "POST",
        "dash/response-permis",
        { idUser: this.idUser, reponse: 0 },
        false
      );
      console.log("verify:", verify);
      if (verify && verify.status == 200) {
        this.$toast.success("Permis de conduire rejeté avec succès !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.init();
      }
    },
    async verifyPermisAccepte() {
      const verify = await apiRequest(
        "POST",
        "dash/response-permis",
        { idUser: this.idUser, reponse: 1 },
        false
      );
      console.log("verify:", verify);
      if (verify && verify.status == 200) {
        this.$toast.success("Permis de conduire validé avec succès !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.init();
      }
    },
    async verifyVehiculeRefuse() {
      const verify = await apiRequest(
        "POST",
        "dash/response-vehicule",
        { idUser: this.idUser, reponse: 0 },
        false
      );
      console.log("verify:", verify);
      if (verify && verify.status == 200) {
        this.$toast.success("Photo de véhicule rejetée avec succès !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.init();
      }
    },
    async verifyVehiculeOtherRefuse(vehicule) {
      const verify = await apiRequest(
        "POST",
        "dash/response-auto-second",
        { autoId: vehicule, reponse: 0 },
        false
      );
      console.log("verify:", verify);
      if (verify && verify.status == 200) {
        this.$toast.success("Photo de véhicule rejetée avec succès !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.init();
      }
    },
    async verifyVehiculeAccepte() {
      const verify = await apiRequest(
        "POST",
        "dash/response-vehicule",
        { idUser: this.idUser, reponse: 1 },
        false
      );
      console.log("verify:", verify);
      if (verify && verify.status == 200) {
        this.$toast.success("Photo de véhicule validée avec succès !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.init();
      }
    },
    async verifyOtherVehiculeAccepte(vehicule) {
      const verify = await apiRequest(
        "POST",
        "dash/response-auto-second",
        { autoId: vehicule, reponse: 1 },
        false
      );
      console.log("verify:", verify);
      if (verify && verify.status == 200) {
        this.$toast.success("Photo de véhicule validée avec succès !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.init();
      }
    },
    async verifyAvisRefuse(idAvis) {
      const verify = await apiRequest(
        "POST",
        "admin/verif-avis",
        { idAvis: idAvis, valide: 0 },
        false
      );
      console.log("verify:", verify);
      if (verify && verify.status == 200) {
        this.$toast.success("Avis rejeté avec succès !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.init();
      }
    },
    async verifyAvisAccepte(idAvis) {
      const verify = await apiRequest(
        "POST",
        "admin/verif-avis",
        { idAvis: idAvis, valide: 1 },
        false
      );
      console.log("verify:", verify);
      if (verify && verify.status == 200) {
        this.$toast.success("Avis publié avec succès !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.init();
      }
    },
    async desactiverUser() {
      const desacUser = await apiRequest(
        "POST",
        "admin/suspendre-user",
        { idUser: this.idUser },
        false
      );
      if (desacUser && desacUser.data) {
        this.init();
        this.$toast.success("Utilisateur suspendu avec succès !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.init();
      }
    },
    async ActiverUser() {
      const activUser = await apiRequest(
        "POST",
        "admin/activer-user",
        { idUser: this.idUser },
        false
      );
      if (activUser && activUser.data) {
        this.init();
        this.$toast.success("Utilisateur activé avec succès !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.init();
      }
    },
    async loadUserGain() {
      const activUser = await apiRequest(
        "GET",
        "gain-parrain/gain-by-user?id=" + this.idUser,
        undefined,
        false
      );
      if (activUser && activUser.data) {
        this.gainCurrentUser.attente = activUser.data.gainAttente
        this.gainCurrentUser.approuve = activUser.data.gainPercu
        this.gainCurrentUser.rejete = activUser.data.gainRejecte
        this.gainCurrentUser.total = activUser.data.totalGain
      }
    },
    async attribuerBadge() {
      const badge = await apiRequest(
        "POST",
        "admin/valider-user",
        {
          idUser: this.idUser,
          attribuer: true,
        },
        false
      );
      if (badge && badge.data) {
        this.init();
        this.$toast.success("Badge attribué avec succès !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.init();
      } else {
        this.$toast.error("Badge non attribué", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },
    async retirerBadge() {
      const retirer = await apiRequest(
        "POST",
        "admin/valider-user",
        {
          idUser: this.idUser,
          attribuer: false,
        },
        false
      );
      if (retirer && retirer.data) {
        this.init();
        this.$toast.success("Badge retiré avec succès !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.init();
      } else {
        this.$toast.error("Badge non attribué", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },
    async addCNI() {
      this.showModalcni = false;
      if (this.fileCni) {
        let formData = new FormData();
        formData.append("idUser", this.idUser);
        formData.append("cni", this.fileCni);
        const returnFilecni = await apiRequest(
          "POST",
          "admin/user-cni-pic",
          formData,
          true
        );
        if (returnFilecni && returnFilecni.data) {
          this.init();
          this.$toast.success("CNI ajouté avec succès !!!", {
            position: "bottom-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        }
      }
    },
    async addProfil() {
      this.showModalprofil = false;
      if (this.fileProfil) {
        let formData = new FormData();
        formData.append("id", this.idUser);
        formData.append("avatar", this.fileProfil);
        const returnFileProfil = await apiRequest(
          "POST",
          "admin/user-pic",
          formData,
          true
        );
        if (returnFileProfil && returnFileProfil.data) {
          this.$toast.success("Photo de profil ajoutée avec succès !!!", {
            position: "bottom-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          this.init();
        }
      }
    },
    async addPermis() {
      this.showModalpermis = false;
      if (this.filePermis) {
        let formData = new FormData();
        formData.append("idUser", this.idUser);
        formData.append("file", this.filePermis);
        const returnFilePermis = await apiRequest(
          "POST",
          "admin/user-permis-pic",
          formData,
          true
        );
        if (returnFilePermis && returnFilePermis.data) {
          this.$toast.success("Permis ajouté avec succès !!!", {
            position: "bottom-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          this.init();
        }
      }
    },
    showModalautoMotoOther(vehicule) {
      this.AutoMoto.id = vehicule
      this.showModalautoMotoOtherPop = true;
    },
    async addAutoMoto() {
      this.showModalautoMoto = false;
      if (this.fileMoto) {
        let formData = new FormData();
        formData.append("idUser", this.idUser);
        formData.append("file", this.fileMoto);
        const returnFileAutoMoto = await apiRequest(
          "POST",
          "admin/user-automoto-pic",
          formData,
          true
        );
        if (returnFileAutoMoto && returnFileAutoMoto.data) {
          this.$toast.success("Auto/Moto ajouté avec succès !!!", {
            position: "bottom-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          this.init();
        }
      }
    },
    //Other auto moto
    async addAutoMotoOther() {
      this.showModalautoMotoOtherPop = false;
      if (this.fileMoto) {
        let formData = new FormData();
        formData.append("idVehicule", this.AutoMoto.id);
        formData.append("file", this.fileMoto);
        const returnFileAutoMoto = await apiRequest(
          "POST",
          "admin/user-automoto-second-pic",
          formData,
          true
        );
        if (returnFileAutoMoto && returnFileAutoMoto.data) {
          this.$toast.success("Auto/Moto ajouté avec succès !!!", {
            position: "bottom-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          this.vehicules = []
          this.showModalautoMotoOtherPop = false;
          this.init();
        }
      }
    },
    // Whatsapp notification
    // async sendMessageWhatsapp() {
    //   var idArray = []
    //   idArray.push(this.idUser)
    //   const message = await apiRequest(
    //     "POST",
    //     "admin/message-users",
    //     {
    //       idUsers: idArray,
    //       typeMessage: this.messageSelect.value,
    //       subject: this.sujet_message,
    //       text: this.message,
    //       media_type: this.mediaSelect.value,//media_type: this.tyMedia.value ? this.tyMedia.value : "",
    //       url: this.url_link,
    //     },
    //     false
    //   );
    //   if (message && message.data) {
    //     this.showSendMessage = false;
    //     this.showMessageMail = false;
    //     this.showMessageWhatsapp = false;
    //     this.$toast.success("Message envoyé avec succès !!!", {
    //       position: "bottom-right",
    //       timeout: 2000,
    //       closeOnClick: true,
    //       pauseOnFocusLoss: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       draggablePercent: 0.6,
    //       showCloseButtonOnHover: false,
    //       hideProgressBar: true,
    //       closeButton: "button",
    //       icon: true,
    //       rtl: false
    //     });
    //   } else {
    //     this.showEchec = true;
    //     this.showSendMessage = false;
    //     this.$toast.error("Opération non effectuée.", {
    //       position: "bottom-right",
    //       timeout: 2000,
    //       closeOnClick: true,
    //       pauseOnFocusLoss: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       draggablePercent: 0.6,
    //       showCloseButtonOnHover: false,
    //       hideProgressBar: true,
    //       closeButton: "button",
    //       icon: true,
    //       rtl: false
    //     });
    //   }
    // },
    messageShow(id, typeMessage) {
      this.messageSelect = typeMessage;
      //console.log('typeMessage:', typeMessage)
      if (typeMessage.value == 0) this.showMessage = true;
      if (typeMessage.value == 1) this.showMessageMail = true;
      if (typeMessage.value == 3) this.showMessageWhatsapp = true;
      this.messageContent.userId = id;
      //console.log(row.id);
    },
    async addMessage() {
      this.showMessage = false;
      this.showMessageMail = false;
      const data = await apiRequest(
        "POST",
        "admin/message-user",
        {
          idUser: this.messageContent.userId,
          typeMessage: this.messageSelect.value,
          subject: this.messageContent.sujet,
          text: this.messageContent.message,
          media_type: this.tyMedia.value ? this.tyMedia.value : "",
          url: this.url_link,
        },
        false
      );
      //console.log("data.data:", data);
      if (data && data.data) {
        this.showSucessM = true;
        this.init();
        console.log(data);
      } else {
        this.showEchecM = true;
        this.init();
        console.log(data);
      }
    },
    async changePass() {
      this.showModalChangePass = false;
      console.log("idUser:", this.idUser);
      const { newpassword, confnewpassword } = this;
      if (
        newpassword == "" ||
        confnewpassword == "" ||
        newpassword != confnewpassword
      ) {
        this.showEchecChange = true;
      } else {
        const data = await apiRequest(
          "POST",
          "admin/user/change-pass",
          {
            idUser: this.idUser,
            password: newpassword,
          },
          false
        );
        if (data && data.data) {
          this.$toast.success("Mot de passe changé avec succès  !!!", {
            position: "bottom-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          this.init();
        } else {
          this.$toast.success("La modification du mot de passe !!!", {
            position: "bottom-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        }
      }
    },
    updateAutomoto(val) {
      this.vehiculeId = val.id
      //console.log(val);
      this.actualImageSrc = val.picture;
      this.AutoMoto.model = val.autoMotoModel;
      this.AutoMoto.id = val.id;
      this.AutoMoto.couleur = val.autoMotoCouleur;
      this.AutoMoto.immatriculation = val.autoMotoImmatriculation;
      this.AutoMoto.motorisation = val.autoMotoMotorisation;
      this.AutoMoto.nombresiege = val.nombreSieges;
      this.AutoMoto.anneeCirculation = val.autoMotoAnneeMiseEnCirculation;
      this.AutoMoto.categorie = val.autoMotoCategorie;
      this.AutoMoto.climatisation =
        val.aClimatisation == true ? "Oui" : "Non";
      this.AutoMoto.clime = val.aClimatisation;
      this.AutoMoto.nomAuto = val.autoMotoName;
      this.AutoMoto.type = val.type;
      this.AutoMoto.cartegrise = val.autoMotoNumeroCarteGrise;
      this.showUpdate = true;
    },
    addNewAutomoto() {
      this.addNewVehicle = true;
    },
    async changeAutomoto() {
      const data = await apiRequest(
        "POST",
        "admin/user/change-automoto",
        {
          id: this.AutoMoto.id,
          user: this.idUser,
          autoMotoName: this.AutoMoto.nomAuto,
          autoMotoImmatriculation: this.AutoMoto.immatriculation,
          autoMotoModel: this.AutoMoto.model,
          autoMotoCouleur: this.AutoMoto.couleur,
          autoMotoMotorisation: this.AutoMoto.motorisation,
          autoMotoAnneeMiseEnCirculation: this.AutoMoto.anneeCirculation,
          aClimatisation: this.AutoMoto.clime,
          nombreSieges: this.AutoMoto.nombresiege,
          type: this.AutoMoto.type,
        },
        false
      );
      if (data && data.data) {
        this.$toast.success("Infos modifié avec succès !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.vehicules = []
        this.init();
      } else {
        this.$toast.error("Auto/Moto non modifié", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
      this.showUpdate = false;
    },
    async changeAutomotoVehicule() {
      const data = await apiRequest(
        "PUT",
        "admin/user-automoto-second-update",
        {
          id: this.AutoMoto.id,
          autoMotoName: this.AutoMoto.nomAuto,
          autoMotoImmatriculation: this.AutoMoto.immatriculation,
          autoMotoModel: this.AutoMoto.model,
          autoMotoCouleur: this.AutoMoto.couleur,
          autoMotoMotorisation: this.AutoMoto.motorisation,
          autoMotoAnneeMiseEnCirculation: this.AutoMoto.anneeCirculation,
          aClimatisation: this.AutoMoto.clime,
          nombreSieges: this.AutoMoto.nombresiege,
        },
        false
      );
      if (data && data.data) {
        this.$toast.success("Auto/Moto changé avec succès !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.init();
      } else {
        this.$toast.error("Auto/Moto non modifié", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
      this.showUpdate = false;
    },
    async addNewVehicleOrBike() {
      this.addNewVehicle = false
      const addAuto = await apiRequest(
        "POST",
        "admin/user-automoto-data",
        {
          idUser: this.idUser,
          autoMotoName: this.AutoMoto.nomAuto,
          autoMotoImmatriculation: this.AutoMoto.immatriculation,
          autoMotoModel: this.AutoMoto.model,
          autoMotoCouleur: this.AutoMoto.couleur,
          autoMotoMotorisation: this.AutoMoto.motorisation,
          autoMotoAnneeMiseEnCirculation: this.AutoMoto.anneeCirculation,
          aClimatisation: this.AutoMoto.clime,
          nombreSieges: this.AutoMoto.nombresiege,
        },
        false
      );
      if (addAuto.data && addAuto.status == 200) {
        this.$toast.success("Informations du véhicule ajouté avec succès !")
      }
    },
    defaultOptionText(val) {
      switch (val) {
        case "ALWAYS":
          return "Toujours";
        case "NEVER":
          return "Jamais";
        case "DEPEND":
          return "Dépend";
        default:
          return ""; // Default text if the value is not recognized
      }
    },
    //Modifier nom user
    async changeName() {
      this.showModalChangeName = false;
      //console.log("idUser:", this.idUser);
      const edit = await apiRequest(
        "PUT",
        "users/" + this.idUser,
        {
          lastname: this.lastname,
          idUser: this.id,
          firstname: this.firstname,
        },
        false
      );
      if (edit && edit.data) {
        this.$toast.success(
          `Le  nom de l'utilisateur a été modifié avec succès !!!`,
          {
            position: "bottom-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          }
        );
        this.init();
      } else {
        this.$toast.error("La modification du nom a échouée", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      } /**/
    },
    async changeTel() {
      this.showModalChangeTel = false;
      const edit = await apiRequest(
        "PUT",
        "users/" + this.idUser,
        {
          idUser: this.idUser,
          indicatifTel: this.indicatif,
          tel: this.tel,
        },
        false
      );
      if (edit && edit.data) {
        this.$toast.success(
          `Le numéro de l'utilisateur a été modifié avec succès !!!`,
          {
            position: "bottom-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          }
        );
      }
      this.init()
    },
    async changeEmail() {
      this.showModalChangeEmail = false;
      const edit = await apiRequest(
        "PUT",
        "users/" + this.idUser,
        {
          idUser: this.idUser,
          email: this.user.email,
        },
        false
      );
      if (edit && edit.data) {
        this.$toast.success(`L'adresse email a été modifié avec succès !!!`, {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.init();
      } else {
        this.$toast.error("La modification du numéro a échouée", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },
    async changeWorkspace() {
      this.showModalWorkspace = false;
      const edit = await apiRequest(
        "PUT",
        "users/" + this.idUser,
        {
          idUser: this.idUser,
          workPlaceName: this.user.villeresidence,
          latWorkPlace: this.user.latWorkPlace,
          lngWorkPlace: this.user.lngWorkPlace,
        },
        false
      );
      if (edit && edit.data) {
        this.$toast.success(
          `Le lieu de travail a été modifié avec succès !!!`,
          {
            position: "bottom-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          }
        );
        this.init();
      } else {
        this.$toast.error("La modification du lieu de travail a échouée", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },
    async changeLieuTravail() {
      this.showModalChangeLieuTravail = false;
      const edit = await apiRequest(
        "PUT",
        "users/" + this.idUser,
        {
          idUser: this.idUser,
          adresse: this.user.villeHabite,
          longitude: this.user.longitude,
          latitude: this.user.latitude
        },
        false
      );
      if (edit && edit.data) {
        this.$toast.success(
          `La ville de résidence a été modifié avec succès !!!`,
          {
            position: "bottom-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          }
        );
        this.init();
      } else {
        this.$toast.error(
          "La modification de la ville de résidence a échouée",
          {
            position: "bottom-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          }
        );
      }
    },
    async changeApropo() {
      this.showModalChangeApropo = false;
      const edit = await apiRequest(
        "PUT",
        "users/" + this.idUser,
        {
          idUser: this.idUser,
          apropos: this.user.apropos,
        },
        false
      );
      if (edit && edit.data) {
        this.$toast.success(
          `Le lieu de travail a été modifié avec succès !!!`,
          {
            position: "bottom-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          }
        );
        this.init();
      } else {
        this.$toast.error("La modification du lieu de travail a échouée", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },
    async changeBday() {
      this.showModalChangeBday = false;
      const edit = await apiRequest(
        "PUT",
        "users/" + this.idUser,
        {
          idUser: this.idUser,
          birthday: this.user.dateNaissance,
        },
        false
      );
      if (edit && edit.data) {
        this.$toast.success(
          `La date de naissance a été modifié avec succès !!!`,
          {
            position: "bottom-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          }
        );
        this.init();
      } else {
        this.$toast.error("La modification de la date de naissance a échouée", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },
    async changePersoneUrgence() {
      this.showModalChangeUrgence = false;
      const edit = await apiRequest(
        "PUT",
        "users/" + this.idUser,
        {
          idUser: this.idUser,
          personneUrgence: this.user.personneUrgence,
          indicatifContactTel: this.user.indicatifContactTel,
          contactUrgence: this.user.contactUrgence,
        },
        false
      );
      if (edit && edit.data) {
        this.$toast.success(
          `Information modifiée avec succès !!!`,
          {
            position: "bottom-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          }
        );
        this.init();
      } else {
        this.$toast.error("La modification a échouée", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },
    setDepartContry: function (place) {
      //console.log('place:', place)
      if (place) {
        //console.log("place:", place);
        /*console.log('place.formatted_address:', place.formatted_address)*/
        this.user.villeHabite = place.formatted_address;
        this.user.latitude = place.geometry.location.lat();
        this.user.longitude = place.geometry.location.lng();
      }
    },
    setDepartContryWorkPlace: function (place) {
      if (place) {
        this.user.villeresidence = place.formatted_address;
        this.user.lngWorkPlace = place.geometry.location.lng();
        this.user.latWorkPlace = place.geometry.location.lat();
      }
    },
    position() {
      Vue.swal({
        position: "center",
        icon: "success",
        title: "Effectuée",
        showConfirmButton: false,
        timer: 3000,
      });
    },
    positionOff() {
      Vue.swal({
        position: "center",
        icon: "error",
        title: "Erreur. Réessayer.",
        showConfirmButton: false,
        timer: 3000,
      });
    },
    focusMyElement() {
      this.$refs.focusThis.focus();
    },
    // loadCurrentVehiculeInfo(infoVehicule) {
    //   console.log(infoVehicule);
    //   this.actualImageSrc = infoVehicule.picture;
    //   this.AutoMoto.model = infoVehicule.autoMotoModel;
    //   this.AutoMoto.id = infoVehicule.vehicule.id;
    //   this.AutoMoto.couleur = infoVehicule.autoMotoCouleur;
    //   this.AutoMoto.immatriculation = infoVehicule.autoMotoImmatriculation;
    //   this.AutoMoto.motorisation = infoVehicule.autoMotoMotorisation;
    //   this.AutoMoto.nombresiege = infoVehicule.nombreSieges;
    //   this.AutoMoto.anneeCirculation = infoVehicule.autoMotoAnneeMiseEnCirculation;
    //   this.AutoMoto.categorie = infoVehicule.autoMotoCategorie;
    //   this.AutoMoto.climatisation =
    //     infoVehicule.aClimatisation == true ? "Oui" : "Non";
    //   this.AutoMoto.clime = infoVehicule.aClimatisation;
    //   this.AutoMoto.nomAuto = infoVehicule.autoMotoName;
    //   this.AutoMoto.cartegrise = infoVehicule.autoMotoNumeroCarteGrise;
    // },
    loadCurrentImageInfo() {
      this.init();
    },
    imageProfileGet(imageVehicule) {
      console.log("le log ", imageVehicule)
      if (imageVehicule != null) {
        return imageVehicule;
      }
      else {
        return require("@/assets/images/users/attente.png");
      }
    }
  },
  watch: {
    mediaSelect(newvalue) {
      console.log(newvalue)
    }
  },
  CloseModalProfil() {
    this.showModalprofil = false;
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-3 firstColonne" style="text-align: center">
                <div class="container-detail-profi">
                  <div class="card-adresse">
                    <div class="icon">
                      <i class="fa fa-mobile" aria-hidden="true"></i>
                    </div>
                    <div class="detail">
                      <div class="user-tel-title">Téléphone</div>
                      <div class="user-tel-phone">
                        <a :href="'https://wa.me/' + user.tel" class="link-color-whatsap" v-b-tooltip.hover
                          title="Envoyer un message whatsapp" target="_blank">
                          {{ user.tel }}
                        </a>
                        <a href="javascript:void(0);" class="link-color-whatsap" v-b-tooltip.hover
                          @click="showModalChangeTel = true" title="Editer">
                          <i class="mdi mdi-pencil iconForm text-color-profil"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="card-adresse">
                    <div class="icon"><i class="fa fa-envelope-open"></i></div>
                    <div class="detail">
                      <div class="user-tel-title">Email</div>
                      <div class="user-tel-phone">
                        {{ user.email }}
                        <a href="javascript:void(0);" class="link-color-whatsap" v-b-tooltip.hover
                          @click="showModalChangeEmail = true" title="Editer">
                          <i class="mdi mdi-pencil iconForm text-color-profil"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="card-adresse">
                    <div class="icon"><i class="fa fa-user-tag"></i></div>
                    <div class="detail">
                      <div class="user-tel-title">Type d'utilisateur</div>
                      <div class="user-tel-phone">{{ user.usertype }}</div>
                    </div>
                  </div>
                  <div class="card-adresse">
                    <div class="icon"><i class="fa fa-signal"></i></div>
                    <div class="detail">
                      <div class="user-tel-title">Nombre d'avis</div>
                      <div class="user-tel-phone">{{ user.nombreVote }}</div>
                    </div>
                  </div>
                  <div class="card-adresse">
                    <div class="icon"><i class="fa fa-star"></i></div>
                    <div class="detail telspace">
                      <div class="user-tel-title">Nombre d'étoiles</div>
                      <div class="user-tel-phone">
                        <star-rating class="mt-1 taille-rate" :rating="user.rating" :read-only="true" :star-size="28"
                          :border-width="2" border-color="#d8d8d8" :rounded-corners="true" :max-rating="5"
                          :show-rating="false"></star-rating>
                        {{ user.rating }}/5
                      </div>
                    </div>
                  </div>
                  <div class="card-adresse">
                    <div class="icon"><i class="fa fa-user-nurse"></i></div>
                    <div class="detail telspace">
                      <div class="user-tel-title">En cas d'urgence</div>
                      <div class="user-tel-phone">
                        {{ user.personneUrgence }} -
                        {{ user.indicatifContactTel }}{{ user.contactUrgence }}
                        <a href="javascript:void(0);" class="link-color-whatsap" v-b-tooltip.hover
                          @click="showModalChangeUrgence = true" title="Editer">
                          <i class="mdi mdi-pencil iconForm text-color-profil"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="card-adresse">
                    <div class="icon">
                      <i class="fa-solid fa-house-laptop"></i>
                    </div>
                    <div class="detail telspace">
                      <div class="user-tel-title">Lieu de travail</div>
                      <div class="user-tel-phone">
                        {{ user.villeresidence }}
                        <a href="javascript:void(0);" class="link-color-whatsap" v-b-tooltip.hover
                          @click="showModalWorkspace = true" title="Editer">
                          <i class="mdi mdi-pencil iconForm text-color-profil"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="card-adresse">
                    <div class="icon"><i class="fa-solid fa-city"></i></div>
                    <div class="detail telspace">
                      <div class="user-tel-title">Ville de résidence</div>
                      <div class="user-tel-phone">
                        {{ user.villeHabite }}
                        <a href="javascript:void(0);" class="link-color-whatsap" v-b-tooltip.hover
                          @click="showModalChangeLieuTravail = true" title="Editer">
                          <i class="mdi mdi-pencil iconForm text-color-profil"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="card-adresse">
                    <div class="icon">
                      <i class="fa-solid fa-address-card"></i>
                    </div>
                    <div class="detail telspace">
                      <div class="user-tel-title">À propos</div>
                      <div class="user-tel-phone">
                        {{ user.apropos }}
                        <a href="javascript:void(0);" class="link-color-whatsap" v-b-tooltip.hover
                          @click="showModalChangeApropo = true" title="Editer">
                          <i class="mdi mdi-pencil iconForm text-color-profil"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="card-adresse">
                    <div class="icon"><i class="fa fa-calendar"></i></div>
                    <div class="detail">
                      <div class="user-tel-title">Date d'inscription</div>
                      <div class="user-tel-phone">{{ user.dateInscription }}</div>
                    </div>
                  </div>
                  <div class="card-adresse">
                    <div class="icon"><i class="fa fa-calendar text-danger"></i></div>
                    <div class="detail">
                      <div class="user-tel-title">Date de naissance</div>
                      <div class="user-tel-phone">{{ user.dateNaissance }}
                        <a href="javascript:void(0);" class="link-color-whatsap" v-b-tooltip.hover
                          @click="showModalChangeBday = true" title="Editer">
                          <i class="mdi mdi-pencil iconForm text-color-profil"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 margiN">
                <div class="row flexible-gain-status">
                  <div class="gain-status">
                    <h3>Gain en attente</h3>
                    <p>{{ gainCurrentUser.attente }}</p>
                  </div>
                  <div class="gain-status">
                    <h3> Gain approuvé</h3>
                    <p>{{ gainCurrentUser.approuve }}</p>
                  </div>
                  <div class="gain-status">
                    <h3> Gain rejeté </h3>
                    <p>{{ gainCurrentUser.rejete }}</p>
                  </div>
                  <div class="gain-status">
                    <h3> Gain total</h3>
                    <p>{{ gainCurrentUser.total }}</p>
                  </div>
                </div>
                <div class="row margin-success">
                  <div class="col-md-12 d-flex justify-content-between">
                    <div class="col-md-6">
                      <div v-if="this.verify.email === true" class=" flexibleLine">
                        <div class="imageContainer">
                          <img class="mr-2" alt src="@/assets/images/verification/correct.svg" width="30" />
                        </div>
                        E-mail vérifié
                      </div>
                      <div v-else class=" flexibleLine">
                        <div class="imageContainer">
                          <img class="mr-2" alt src="@/assets/images/verification/multiply.svg" width="30" />
                        </div>
                        E-mail non vérifié
                      </div>
                      <div v-if="this.verify.permis === true" class="mt-4 flexibleLine">
                        <div class="imageContainer">
                          <img class="mr-2" alt src="@/assets/images/verification/correct.svg" width="30" />
                        </div>
                        Permis vérifié
                      </div>
                      <div v-else class="mt-4 flexibleLine">
                        <div class="imageContainer">
                          <img class="mr-2" alt src="@/assets/images/verification/multiply.svg" width="30" />
                        </div>
                        Permis non vérifié
                      </div>
                      <div v-if="this.verify.autoMoto === true" class="mt-4 flexibleLine">
                        <div class="imageContainer">
                          <img class="mr-2" alt src="@/assets/images/verification/correct.svg" width="30" />
                        </div>
                        Véhicule vérifié
                      </div>
                      <div v-else class="mt-4 flexibleLine">
                        <div class="imageContainer">
                          <img class="mr-2" alt src="@/assets/images/verification/multiply.svg" width="30" />
                        </div>
                        Véhicule non vérifié
                      </div>
                      <div v-if="this.verify.image === true" class="mt-4 flexibleLine">
                        <div class="imageContainer">
                          <img class="mr-2" alt src="@/assets/images/verification/correct.svg" width="30" />
                        </div>
                        Photo de profil vérifié
                      </div>
                      <div v-else class="mt-4 flexibleLine">
                        <div class="imageContainer">
                          <img class="mr-2" alt src="@/assets/images/verification/multiply.svg" width="30" />
                        </div>
                        Photo de profil non vérifié
                      </div>
                      <div v-if="this.verify.tel === true" class="mt-4 flexibleLine">
                        <div class="imageContainer">
                          <img class="mr-2" alt src="@/assets/images/verification/correct.svg" width="30" />
                        </div>
                        Numéro de téléphone vérifié
                      </div>
                      <div v-else class="mt-4 flexibleLine">
                        <div class="imageContainer">
                          <img class="mr-2" alt src="@/assets/images/verification/multiply.svg" width="30" />
                        </div>
                        Numéro de téléphone non vérifié
                      </div>
                      <div v-if="this.verify.cni === true" class="mt-4 flexibleLine">
                        <div class="imageContainer">
                          <img class="mr-2" alt src="@/assets/images/verification/correct.svg" width="30" />
                        </div>
                        Pièce d'identité vérifiée
                      </div>
                      <div v-else class="mt-4 flexibleLine">
                        <div class="imageContainer">
                          <img class="mr-2" alt src="@/assets/images/verification/multiply.svg" width="30" />
                        </div>
                        Pièce d'identité non vérifiée
                      </div>
                      <!-- </div> -->
                    </div>
                    <div class="col-md-6 margiN">
                      <div v-if="this.pref.acceptPeopleDisabilities == 'NEVER'" class=" flexibleLine">
                        <div class="imageContainer">
                          <img class="mr-2" alt src="@/assets/images/verification/multiply.svg" width="30" />
                        </div>
                        Je n'accepte pas les PMR
                      </div>
                      <div v-else class="flexibleLine">
                        <div class="imageContainer">
                          <img class="mr-2" alt src="@/assets/images/verification/correct.svg" width="30" />
                        </div>
                        J'accepte les PMR
                      </div>
                      <div v-if="this.pref.discussInCar == 'NEVER'" class="mt-4 flexibleLine">
                        <div class="imageContainer">
                          <img class="mr-2" alt src="@/assets/images/verification/multiply.svg" width="30" />
                        </div>
                        Je n'aime pas discuter en voiture
                      </div>
                      <div v-else class="mt-4 flexibleLine">
                        <div class="imageContainer">
                          <img class="mr-2" alt src="@/assets/images/verification/correct.svg" width="30" />
                        </div>
                        J'aime discuter en voiture
                      </div>
                      <div v-if="this.pref.eatInCar == 'NEVER'" class="mt-4 flexibleLine">
                        <div class="imageContainer">
                          <img class="mr-2" alt src="@/assets/images/verification/multiply.svg" width="30" />
                        </div>
                        Je n'aime pas manger en voiture
                      </div>
                      <div v-else class="mt-4 flexibleLine">
                        <div class="imageContainer">
                          <img class="mr-2" alt src="@/assets/images/verification/correct.svg" width="30" />
                        </div>
                        J'aime manger en voiture
                      </div>
                      <div v-if="this.pref.enjoyMusicInCar == 'NEVER'" class="mt-4 flexibleLine">
                        <div class="imageContainer">
                          <img class="mr-2" alt src="@/assets/images/verification/multiply.svg" width="30" />
                        </div>
                        Je n'écoute pas la musique en voiture
                      </div>
                      <div v-else class="mt-4 flexibleLine">
                        <div class="imageContainer">
                          <img class="mr-2" alt src="@/assets/images/verification/correct.svg" width="30" />
                        </div>
                        J'écoute la musique en voiture
                      </div>
                      <div v-if="this.pref.smokeInCar == 'NEVER'" class="mt-4 flexibleLine">
                        <div class="imageContainer">
                          <img class="mr-2" alt src="@/assets/images/verification/multiply.svg" width="30" />
                        </div>
                        Je n'aime pas fumer en voiture
                      </div>
                      <div v-else class="mt-4 flexibleLine">
                        <div class="imageContainer">
                          <img class="mr-2" alt src="@/assets/images/verification/correct.svg" width="30" />
                        </div>
                        J'aime fumer en voiture
                      </div>
                      <div v-if="this.pref.tolerateStops == 'NEVER'" class="mt-4 flexibleLine">
                        <div class="imageContainer">
                          <img class="mr-2" alt src="@/assets/images/verification/multiply.svg" width="30" />
                        </div>
                        Je ne tolère pas les arrêts
                      </div>
                      <div v-else class="mt-4 flexibleLine">
                        <div class="imageContainer">
                          <img class="mr-2" alt src="@/assets/images/verification/correct.svg" width="30" />
                        </div>
                        Je tolère les arrêts
                      </div>
                      <!-- </div> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-2 ml-2 float-right buttonList" style="margin-top: 10px">
                <div class="flexibleImage">
                  <a class="image-popup-vertical-fit marginImage" @click="showImage">
                    <img style="cursor: pointer" class="avatar-xl imageProfile" :src="`${this.allImage.avatar}`"
                      data-holder-rendered="true" />
                  </a>
                  <vue-easy-lightbox :visible="vue" :imgs="img" @hide="handleHide"></vue-easy-lightbox>
                  <div class="mt-2 text-center">
                    <span class="username"> {{ user.username }}</span>
                    <a href="javascript:void(0);" class="text-primary" v-b-tooltip.hover
                      @click="showModalChangeName = true" title="Editer">
                      <i class="mdi mdi-pencil iconForm text-color-profil"></i>
                    </a>
                  </div>
                  <div class="text-center">
                    <span class="user-grade"> {{ user.level }} </span>
                  </div>
                </div>
                <!--   <div  >
                      <button
                        title="Solde Wallet "
                        style="background: maroon; color: white;"
                        class="btn mb-2 mr-2 "
                        disabled
                      >
                        <i class="mdi mdi-wallet align-middle mr-2"></i>Solde :
                        {{ user.solde }} XOF
                      </button>
                    </div> -->
                <div style="text-align: center" class="otherBtn">
                  <button title="Solde Wallet " style="background: maroon; color: white;" class="btn mb-2 mr-2 "
                    disabled>
                    <i class="mdi mdi-wallet align-middle mr-2"></i>Solde :
                    {{ user.solde }} XOF
                  </button>
                  <button v-if="this.user.active == true" type="submit" @click="desactiverUser"
                    class="btn btn-warning mb-2 mr-2">
                    <i class="mdi mdi-account-remove mr-2"></i>Désactiver ce
                    compte
                  </button>
                  <button v-else type="submit" @click="ActiverUser" class="btn btn-info mb-2 mr-2">
                    <i class="mdi mdi-account-remove mr-2"></i>Activer ce compte
                  </button>
                  <button type="submit" class="btn btn-danger mb-2 mr-2 supprBtn">
                    <i class="ri-close-line align-middle mr-2"></i>Supprimer ce compte
                  </button>
                  <button v-if="this.user.badge == true" type="submit" @click="retirerBadge"
                    class="btn btn-info mb-2 mr-2">
                    <i class="mdi mdi-certificate align-middle mr-2"></i>Retirer
                    le badge
                  </button>
                  <button v-else type="submit" @click="attribuerBadge" class="btn btn-success mb-2 mr-2">
                    <i class="mdi mdi-certificate align-middle mr-2"></i>Attribuer un badge
                  </button>
                  <button style="background: orange; color: black" type="submit" @click="
      messageShow(idUser, {
        name: 'Notification',
        value: 0,
      })
      " title="Notification" class="btn mb-2 mr-2 text-white">
                    <i class="fas fa-bell align-middle"></i>Envoyer une notification
                  </button>
                  <button style="background: rgb(13, 135, 135); color: black" type="submit"
                    @click="messageShow(idUser, { name: 'Mail', value: 1 })" title="Mail"
                    class="btn mb-2 mr-2 text-white">
                    <i class="fas fa-envelope align-middle"></i> Envoyer un mail
                  </button>
                  <!-- <button
                        style="background: rgb(9, 47, 47); color: #fff"
                        type="submit"
                        @click="messageShow(idUser, { name: 'Whatsapp', value: 3 })"
                        title="Whatsapp"
                        class="btn mb-2 mr-2"
                      >
                        <i class="fas fa-envelope align-middle"></i> Notification whatsapp
                      </button> -->
                  <button style="background: red; color: black" type="submit" @click="showModalChangePass = true"
                    title="Modification mot de passe" class="btn mb-2 mr-2 text-white">
                    <i class="fas fa-key align-middle text-white"></i> Éditer mot de passe
                  </button>
                  <button class='btn btn-primary mb-2 mr-2' @click="showModalPref = true"><i class="fa fa-pencil"></i>
                    Editer les préférences
                  </button>
                  <div class=" d-flex justify-content-center row">
                    <button class='col-md-5 d-block btn btn-success mb-2 mr-2 ' @click="crediteModal()"><i
                        class="fa fa-wallet"></i>
                      Créditer
                    </button>
                    <button class='col-md-5 d-block btn btn-danger mb-2 ' @click="retraitModal()"><i
                        class="fa fa-wallet"></i>
                      Retirer
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="card">
        <div class="card-body mt-3">
          <b-tabs pills justified content-class=" text-muted">
            <b-tab active class="border-0">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="fas fa-home"></i>
                </span>
                <span class="d-none d-sm-inline-block"><i class="mdi mdi-clipboard-account mr-2"></i>Vérifications &
                  Avis</span>
              </template>
              <div class="row">
                <div class="col-md-12">
                  <h3 class="textTitle">Vérifications</h3>
                  <div class="containerInfoProfile">
                    <div class="cards">
                      <div class="imgBx" @click="showImage">
                        <img alt="profile" :src="`${this.allImage.avatar}`" />
                        <vue-easy-lightbox :visible="visible" :imgs="imgs" @hide="handleHide"></vue-easy-lightbox>
                      </div>
                      <div class="contentProfile">
                        <div class="details">
                          <h2>Profil</h2>
                          <ul class="social_icons">
                            <li>
                              <a href="#" @click.stop.prevent="showModalprofil = true"><i class="fa fa-upload"
                                  aria-hidden="true"></i></a>
                            </li>
                            <li>
                              <a href="#" @click.stop.prevent="verifyPictureRefuse" id="echec"><i class="fa fa-times"
                                  aria-hidden="true"></i></a>
                            </li>
                            <li>
                              <a href="#" v-if="this.verify.image == false" @click.stop.prevent="verifyPictureAccepte"
                                id="validate"><i class="fa fa-check" aria-hidden="true"></i></a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="cards">
                      <div class="imgBx" @click="showImage">
                        <img alt="profile" :src="`${this.allImage.cni}`" />
                        <vue-easy-lightbox :visible="visible" :imgs="imgs" @hide="handleHide"></vue-easy-lightbox>
                      </div>
                      <div class="contentProfile">
                        <div class="details">
                          <h2>CNI</h2>
                          <ul class="social_icons">
                            <li>
                              <a href="#" @click.stop.prevent="showModalcni = true"><i class="fa fa-upload"
                                  aria-hidden="true"></i></a>
                            </li>
                            <li>
                              <a href="#" @click.stop.prevent="verifyCniRefuse" id="echec"><i class="fa fa-times"
                                  aria-hidden="true"></i></a>
                            </li>
                            <li>
                              <a href="#" v-if="this.verify.cni == false" @click.stop.prevent="verifyCniAccepte"
                                id="validate"><i class="fa fa-check" aria-hidden="true"></i></a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="cards">
                      <div class="imgBx" @click="showImage">
                        <img alt="profile" :src="`${this.allImage.permis}`" />
                        <vue-easy-lightbox :visible="visible" :imgs="imgs" @hide="handleHide"></vue-easy-lightbox>
                      </div>
                      <div class="contentProfile">
                        <div class="details">
                          <h2>PERMIS</h2>
                          <ul class="social_icons">
                            <li>
                              <a href="#" @click.stop.prevent="showModalpermis = true"><i class="fa fa-upload"
                                  aria-hidden="true"></i></a>
                            </li>
                            <li>
                              <a href="#" @click.stop.prevent="verifyPermisRefuse" id="echec"><i class="fa fa-times"
                                  aria-hidden="true"></i></a>
                            </li>
                            <li>
                              <a href="#" v-if="this.verify.permis == false" @click.stop.prevent="verifyPermisAccepte"
                                id="validate"><i class="fa fa-check" aria-hidden="true"></i></a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <!-- <div v-for="v in vehicules" :key="v.id">
                      <div class="cards" v-if="v.idSecond !== null">
                        <div>
                          <div class="imgBx" @click="showImage">
                            <img alt="profile" :src="v.picture" />
                            <vue-easy-lightbox :visible="visible" :imgs="imgs" @hide="handleHide"></vue-easy-lightbox>
                          </div>
                          <div class="contentProfile">
                            <div class="details">
                              <h2>AUTO/MOTO</h2>
                              <ul class="social_icons">
                                <li>
                                  <a href="#" @click.stop.prevent="showModalautoMoto = true"><i class="fa fa-upload"
                                      aria-hidden="true"></i></a>
                                </li>
                                <li>
                                  <a href="#" @click.stop.prevent="verifyVehiculeRefuse" id="echec"><i class="fa fa-times"
                                      aria-hidden="true"></i></a>
                                </li>
                                <li>
                                  <a href="#" v-if="v.autoMotoVerified == false"
                                    @click.stop.prevent="verifyVehiculeAccepte" id="validate"><i class="fa fa-check"
                                      aria-hidden="true"></i></a>
                                </li>
                                <li>
                                  <a href="#" @click.stop.prevent="loadCurrentImageInfo()" id="validate"><i
                                      class="fa fa-info" aria-hidden="true"></i></a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> -->
                  </div>
                  <!-- <h3 class="textTitle" v-if="list_other_vehicule.length != 0">Les autres véhicules</h3>
                  <div class="containerInfoProfiles" v-if="list_other_vehicule.length != 0">
                    <div v-for="(vehicule, index ) in list_other_vehicule" :key="index">
                      <div class="cards" v-if="vehicule.idSecond === null">
                        <div>
                          <div class="imgBx" @click="showImage">
                            <img alt="profile" :src="vehicule.picture" />
                            <vue-easy-lightbox :visible="visible" :imgs="imgs" @hide="handleHide"></vue-easy-lightbox>
                          </div>
                          <div class="contentProfile">
                            <div class="details">
                              <h2>Vehicule {{ index + 1 }}</h2>
                              <ul class="social_icons">
                                <li>
                                  <a href="#" @click.stop.prevent="showModalautoMotoOther(vehicule.id)"><i
                                      class="fa fa-upload" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                  <a href="#" @click.stop.prevent="verifyVehiculeOtherRefuse(vehicule.id)" id="echec"><i
                                      class="fa fa-times" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                  <a href="#" v-if="vehicule.autoMotoVerified == false"
                                    @click.stop.prevent="verifyOtherVehiculeAccepte(vehicule.id)" id="validate"><i
                                      class="fa fa-check" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                  <a href="#" @click.stop.prevent="loadCurrentVehiculeInfo(vehicule)" id="validate"><i
                                      class="fa fa-info" aria-hidden="true"></i></a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->

                  <div class="col-md-12">
                    <div class="card">
                      <div class="card-body">
                        <div class="card-title">
                          <h3 class="textTitle">Informations Auto/Moto</h3>
                          <button type="submit" @click="addNewAutomoto()" class="btn r-bg-color  mb-2 mr-2">
                            <i class="mdi mdi-pencil font-size-18 mr-2"></i>Ajouter </button>
                        </div>
                        <div v-for="car in vehicules" :key="car.id">
                          <b-card v-if="car.idSecond !== null" header-class="bg-transparent border-primary"
                            class="border border-rcolis cardCENTER pt-0">
                            <div v-if="car.idSecond !== null"
                              class="row d-flex  justify-content-end align-items-center mt-0 mr-0 mb-2 fs-4"
                              style="position: absolute; top:0%; right:0%; ">
                              <div>
                                <button variant="success" class=" btn r-bg-color fs-4 "> <span
                                    class="fs-4">Principal</span> </button>
                              </div>
                            </div>
                            <div class="row d-flex justify-content-center align-items-center">
                              <div class="col-md-4">
                                <a class="image-popup-vertical-fit formImage" @click="showImage">
                                  <img class="img-fluid " alt="carpic" :src="car.picture" width="250" height="200" />
                                </a>
                                <vue-easy-lightbox :visible="visible" :imgs="imgs"
                                  @hide="handleHide"></vue-easy-lightbox>
                                <div class="row d-flex justify-content-center mt-2" style="max-width:280px !important">
                                  <div class="col-md-3  bg-gray p-2">
                                    <span class="d-flex justify-content-center rounded-circle p-2"
                                      style="width:45px; height:45px; background-color:#ff8C00; font-size:1.5em">
                                      <a href="#" @click="updateAutomoto(car)">
                                        <i class="fa fa-pen" style="color:#ffffff !important"></i>
                                      </a>
                                    </span>
                                  </div>
                                  <div class="col-md-3  bg-gray p-2">
                                    <span class="d-flex justify-content-center rounded-circle p-2"
                                      style="width:45px; height:45px; background-color:#1E90FF; font-size:1.5em">
                                      <a href="#" @click.stop.prevent="showModalautoMotoOther(car.id)"><i
                                          class="fa fa-upload" aria-hidden="true"
                                          style="color:#ffffff !important"></i></a>
                                    </span>
                                  </div>
                                  <div class="col-md-3  bg-gray p-2">
                                    <span class="d-flex justify-content-center rounded-circle p-2"
                                      style="width:45px; height:45px; background-color:#FF0000; font-size:1.5em">
                                      <a href="#" @click.stop.prevent="verifyVehiculeOtherRefuse(car.id)" id="echec"><i
                                          class="fa fa-times" aria-hidden="true"
                                          style="color:#ffffff !important"></i></a>
                                    </span>
                                  </div>
                                  <div class="col-md-3  bg-gray p-2">
                                    <span v-if="car.autoMotoVerified == false"
                                      class="d-flex justify-content-center rounded-circle p-2"
                                      style="width:45px; height:45px; background-color:#24BC24; font-size:1.5em">
                                      <a href="#" @click.stop.prevent="verifyOtherVehiculeAccepte(car.id)"
                                        id="validate"><i class="fa fa-check" aria-hidden="true"></i></a>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div>
                                  <div class="theTitle">Modèle</div>
                                  <div class="mt-2">
                                    {{ car.autoMotoModel }}
                                  </div>
                                </div>
                                <div class="mt-2">
                                  <div class="theTitle">Marque</div>
                                  <div>{{ car.autoMotoName }}</div>
                                </div>
                                <div class="mt-2">
                                  <div class="theTitle">Couleur</div>
                                  <div>{{ car.autoMotoCouleur }}</div>
                                </div>
                                <div class="mt-2">
                                  <div class="theTitle">Immatriculation</div>
                                  <div>{{ car.autoMotoImmatriculation }}</div>
                                </div>
                                <div class="mt-2">
                                  <div class="theTitle">Type de véhicule</div>
                                  <div>{{ car.type || "non spécifié" }}</div>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="">
                                  <div class="theTitle">Motorisation</div>
                                  <div>{{ car.autoMotoMotorisation }}</div>
                                </div>
                                <div class="mt-2">
                                  <div class="theTitle">Climatisation</div>
                                  <div> <span v-if="car.aClimatisation === true"> Oui</span> <span
                                      v-if="car.aClimatisation === false"> Non</span></div>
                                </div>
                                <div class="mt-2">
                                  <div class="theTitle">Année de circulation</div>
                                  <div>{{ car.autoMotoAnneeMiseEnCirculation }}</div>
                                </div>
                                <!--<div class="mt-2">
                                    Numéro carte grise : {{ AutoMoto.cartegrise }}
                                  </div>-->
                                <div class="mt-2">
                                  <div class="theTitle">Nombre de siège</div>
                                  <div>{{ car.nombreSieges }}</div>
                                </div>
                              </div>
                            </div>
                          </b-card>
                        </div>
                        <div v-for="car in vehicules" :key="car.id">
                          <b-card v-if="car.idSecond === null" header-class="bg-transparent border-primary"
                            class="border border-rcolis cardCENTER pt-0">
                            <div v-if="car.idSecond !== null"
                              class="row d-flex  justify-content-end align-items-center mt-0 mr-0 mb-2 fs-4"
                              style="position: absolute; top:0%; right:0%; ">
                              <div>
                                <button variant="success" class=" btn r-bg-color fs-4 "> <span
                                    class="fs-4">Principal</span> </button>
                              </div>
                            </div>
                            <div class="row d-flex justify-content-center align-items-center">
                              <div class="col-md-4">
                                <a class="image-popup-vertical-fit formImage" @click="showImage">
                                  <img class="img-fluid " alt="carpic" :src="car.picture" width="250" height="200" />
                                </a>
                                <vue-easy-lightbox :visible="visible" :imgs="imgs"
                                  @hide="handleHide"></vue-easy-lightbox>
                                <div class="row d-flex justify-content-center mt-2" style="max-width:280px !important">
                                  <div class="col-md-3  bg-gray p-2">
                                    <span class="d-flex justify-content-center rounded-circle p-2"
                                      style="width:45px; height:45px; background-color:#ff8C00; font-size:1.5em">
                                      <a href="#" @click="updateAutomoto(car)">
                                        <i class="fa fa-edit"></i>
                                      </a>
                                    </span>
                                  </div>
                                  <div class="col-md-3  bg-gray p-2">
                                    <span class="d-flex justify-content-center rounded-circle p-2"
                                      style="width:45px; height:45px; background-color:#1E90FF; font-size:1.5em">
                                      <a href="#" @click.stop.prevent="showModalautoMotoOther(car.id)"><i
                                          class="fa fa-upload" aria-hidden="true"></i></a>
                                    </span>
                                  </div>
                                  <div class="col-md-3  bg-gray p-2">
                                    <span class="d-flex justify-content-center rounded-circle p-2"
                                      style="width:45px; height:45px; background-color:#FF0000; font-size:1.5em">
                                      <a href="#" @click.stop.prevent="verifyVehiculeOtherRefuse(car.id)" id="echec"><i
                                          class="fa fa-times" aria-hidden="true"></i></a>
                                    </span>
                                  </div>
                                  <div class="col-md-3  bg-gray p-2">
                                    <span v-if="car.autoMotoVerified == false"
                                      class="d-flex justify-content-center rounded-circle p-2"
                                      style="width:45px; height:45px; background-color:#24BC24; font-size:1.5em">
                                      <a href="#" @click.stop.prevent="verifyOtherVehiculeAccepte(car.id)"
                                        id="validate"><i class="fa fa-check" aria-hidden="true"></i></a>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div>
                                  <div class="theTitle">Modèle</div>
                                  <div class="mt-2">
                                    {{ car.autoMotoModel }}
                                  </div>
                                </div>
                                <div class="mt-2">
                                  <div class="theTitle">Marque</div>
                                  <div>{{ car.autoMotoName }}</div>
                                </div>
                                <div class="mt-2">
                                  <div class="theTitle">Couleur</div>
                                  <div>{{ car.autoMotoCouleur }}</div>
                                </div>
                                <div class="mt-2">
                                  <div class="theTitle">Immatriculation</div>
                                  <div>{{ car.autoMotoImmatriculation }}</div>
                                </div>
                                <div class="mt-2">
                                  <div class="theTitle">Type de véhicule</div>
                                  <div>{{ car.type || "non spécifié" }}</div>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="">
                                  <div class="theTitle">Motorisation</div>
                                  <div>{{ car.autoMotoMotorisation }}</div>
                                </div>
                                <div class="mt-2">
                                  <div class="theTitle">Climatisation</div>
                                  <div> <span v-if="car.aClimatisation === true"> Oui</span> <span
                                      v-if="car.aClimatisation === false"> Non</span></div>
                                </div>
                                <div class="mt-2">
                                  <div class="theTitle">Année de circulation</div>
                                  <div>{{ car.autoMotoAnneeMiseEnCirculation }}</div>
                                </div>
                                <!--<div class="mt-2">
                                    Numéro carte grise : {{ AutoMoto.cartegrise }}
                                  </div>-->
                                <div class="mt-2">
                                  <div class="theTitle">Nombre de siège</div>
                                  <div>{{ car.nombreSieges }}</div>
                                </div>
                              </div>
                            </div>
                          </b-card>
                        </div>

                        <div>

                          <h3>Assurance</h3>
                          <b-card header-class="bg-transparent border-primary"
                            class="border border-rcolis cardCENTER pt-0">
                            <div class="row d-flex  justify-content-end align-items-center mt-0 mr-0 mb-2 fs-4"
                              style="position: absolute; top:0%; right:0%; ">
                              <div>
                                <!-- <button variant="success" class=" btn r-bg-color fs-4 "> <span
                                    class="fs-4">Principal</span> </button> -->
                              </div>
                            </div>
                            <div v-if="permisObject !== null " class="row d-flex justify-content-center align-items-center">
                              <div class="col-md-4">
                                <a class="image-popup-vertical-fit formImage" @click="showImage">
                                  <img class="img-fluid " alt="carpic" :src="permisObject.picture ? permisObject.picture : ''" width="250"
                                    height="200" />
                                </a>
                                <vue-easy-lightbox :visible="visible" :imgs="imgs"
                                  @hide="handleHide"></vue-easy-lightbox>
                                <!-- <div class="row d-flex justify-content-center mt-2" style="max-width:280px !important">
                                  <div class="col-md-3  bg-gray p-2">
                                    <span class="d-flex justify-content-center rounded-circle p-2"
                                      style="width:45px; height:45px; background-color:#ff8C00; font-size:1.5em">
                                      <a href="#" @click="updateAutomoto(car)">
                                        <i class="fa fa-edit"></i>
                                      </a>
                                    </span>
                                  </div>
                                  <div class="col-md-3  bg-gray p-2">
                                    <span class="d-flex justify-content-center rounded-circle p-2"
                                      style="width:45px; height:45px; background-color:#1E90FF; font-size:1.5em">
                                      <a href="#" @click.stop.prevent="showModalautoMotoOther(car.id)"><i
                                          class="fa fa-upload" aria-hidden="true"></i></a>
                                    </span>
                                  </div>
                                  <div class="col-md-3  bg-gray p-2">
                                    <span class="d-flex justify-content-center rounded-circle p-2"
                                      style="width:45px; height:45px; background-color:#FF0000; font-size:1.5em">
                                      <a href="#" @click.stop.prevent="verifyVehiculeOtherRefuse(car.id)" id="echec"><i
                                          class="fa fa-times" aria-hidden="true"></i></a>
                                    </span>
                                  </div>
                                  <div class="col-md-3  bg-gray p-2">
                                    <span v-if="car.autoMotoVerified == false"
                                      class="d-flex justify-content-center rounded-circle p-2"
                                      style="width:45px; height:45px; background-color:#24BC24; font-size:1.5em">
                                      <a href="#" @click.stop.prevent="verifyOtherVehiculeAccepte(car.id)"
                                        id="validate"><i class="fa fa-check" aria-hidden="true"></i></a>
                                    </span>
                                  </div>
                                </div> -->
                              </div>
                              <div class="col-md-4">
                                <div>
                                  <div class="theTitle">Assureur</div>
                                  <div class="mt-2">
                                    {{ permisObject.assureur }}
                                  </div>
                                </div>
                                <div class="mt-2">
                                  <div class="theTitle">Lieu de délivrance</div>
                                  <div>{{ permisObject.deliveredPlace }}</div>
                                </div>
                                <div class="mt-2">
                                  <div class="theTitle">Type d'assurance</div>
                                  <div>{{ permisObject.typeAssurance }}</div>
                                </div>

                                <!-- <div class="mt-2">
                                  <div class="theTitle">Type de véhicule</div>
                                  <div>{{ car.type || "non spécifié" }}</div>
                                </div> -->
                              </div>
                              <div class="col-md-4">
                                <div class="">
                                  <div class="mt-2">
                                    <div class="theTitle">Date de délivrance</div>
                                    <div>{{ new Date(permisObject.startDate).toLocaleDateString() }}</div>
                                  </div>
                                  <div class="mt-2">
                                    <div class="theTitle">Date d'expiration</div>
                                    <div>{{ new Date(permisObject.assuranceEndAt).toLocaleDateString() }}</div>
                                  </div>

                                </div>



                              </div>
                            </div>
                          </b-card>




                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="card-title">
                        <h4>Avis</h4>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="card-title text-center mb-5">
                            <h5 class="bg-warning" style="font-size: 20px">
                              Avis reçus
                            </h5>
                          </div>
                          <div class="media border-bottom pb-3" v-for="(item, index) in avisrecus" :key="index">
                            <div class="media-body">
                              <div>
                                <p class="text-muted mt-2">
                                  {{ item.avis }}
                                </p>
                                <div class="row d-flex justify-content-between">
                                  <h5 class="font-size-15 mb-3 ml-2">
                                    {{ item.lastname + " " + item.firstname }}
                                  </h5>
                                  <span class="float-sm-right mt-0 font-size-12">
                                    {{ item.dateAvis }}
                                  </span>
                                  <div class="float-right" v-if="item.rating == 1">
                                    <div class="row">
                                      <button @click="verifyAvisAccepte(item.id)" class="btn btn-success"
                                        v-if="item.visible == false">
                                        <i class="fa fa-check"></i>
                                      </button>
                                      <button @click="verifyAvisRefuse(item.id)" class="btn btn-danger">
                                        <i class="fa fa-times"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="card-title text-left">
                            <h5 class="bg-success text-center mb-5" style="font-size: 20px">
                              Avis laissés
                            </h5>
                          </div>
                          <div class="media border-bottom pb-3" v-for="(item, index) in avislaisses" :key="index">
                            <div class="media-body">
                              <div>
                                <p class="text-muted mt-2 ml-3">
                                  {{ item.avis }}
                                </p>
                                <div class="row d-flex justify-content-between">
                                  <h5 class="font-size-15 mb-3 ml-4">
                                    {{ item.lastname + " " + item.firstname }}
                                  </h5>
                                  <span class="float-sm-right mt-0 font-size-12">
                                    {{ item.dateAvis }}
                                  </span>
                                  <div class="float-right" v-if="item.rating == 1">
                                    <div class="row">
                                      <button @click="verifyAvisAccepte(item.id)" class="btn btn-success"
                                        v-if="item.visible == false">
                                        <i class="fa fa-check"></i>
                                      </button>
                                      <button @click="verifyAvisRefuse(item.id)" class="btn btn-danger">
                                        <i class="fa fa-times"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="far fa-user"></i>
                </span>
                <span class="d-none d-sm-inline-block"><i class="mdi mdi-format-list-bulleted mr-2"></i>Activités</span>
              </template>
              <div>
                <h2 class="text-primary">
                  En tant que
                  <span style="cursor: pointer" class="text-success"
                    @click="HistoriqueConducteur = true">conducteur</span>
                </h2>
                <hr class="divider bg-primary mb-4" />
                <div class="row">
                  <div class="col-md-2">
                    <b-card header-class="bg-transparent border-primary" class="myCard">
                      <template v-slot:header>
                        <h5 class="my-0 infotitle">Trajets publiés</h5>
                      </template>
                      <div class="float-right alignBottom">
                        <h1 class="text-primary">{{ activity.publies }}</h1>
                      </div>
                    </b-card>
                  </div>
                  <div class="col-md-2">
                    <b-card header-class="bg-transparent" class="myCard">
                      <template v-slot:header>
                        <h5 class="my-0 infotitle">Trajets effectués</h5>
                      </template>
                      <div class="float-right alignBottom">
                        <h1 class="text-success">{{ activity.effectues }}</h1>
                      </div>
                    </b-card>
                  </div>
                  <div class="col-md-2">
                    <b-card header-class="bg-transparent border-danger" class="myCard">
                      <template v-slot:header>
                        <h5 class="my-0 infotitle">Trajets annulés</h5>
                      </template>
                      <div class="float-right alignBottom">
                        <h1 class="text-info">{{ activity.annulees }}</h1>
                      </div>
                    </b-card>
                  </div>
                  <div class="col-md-2">
                    <b-card header-class="bg-transparent border-success" class="myCard">
                      <template v-slot:header>
                        <h5 class="my-0 infotitle">Trajets à venir</h5>
                      </template>
                      <div class="float-right alignBottom">
                        <h1 class="text-warning">{{ activity.a_venir }}</h1>
                      </div>
                    </b-card>
                  </div>
                  <div class="col-md-4">
                    <b-card header-class="bg-transparent border-success" class="myCard">
                      <template v-slot:header>
                        <h5 class="my-0 infotitle">Gains</h5>
                      </template>
                      <div class="float-right alignBottom">
                        <h1 class="text-blueG">{{ activity.gains }} XOF</h1>
                      </div>
                    </b-card>
                  </div>
                </div>
              </div>
              <div class="mt-2">
                <h2 class="text-primary">
                  En tant que
                  <span style="cursor: pointer" class="text-success" @click="HistoriquePassager = true">passager</span>
                </h2>
                <hr class="divider bg-primary mb-4" />
                <div class="row">
                  <div class="col-md-4">
                    <b-card header-class="bg-transparent border-primary" class="myCard">
                      <template v-slot:header>
                        <h5 class="my-0 infotitle">Réservations éffectuées</h5>
                      </template>
                      <div class="float-right alignBottom">
                        <h1 class="text-primary">
                          {{ activity.reservations_effectuees }}
                        </h1>
                      </div>
                    </b-card>
                  </div>
                  <div class="col-md-4">
                    <b-card header-class="bg-transparent" class="myCard">
                      <template v-slot:header>
                        <h5 class="my-0 infotitle">Voyages effectués</h5>
                      </template>
                      <div class="float-right alignBottom">
                        <h1 class="text-success">
                          {{ activity.voyages_effectues }}
                        </h1>
                      </div>
                    </b-card>
                  </div>
                  <div class="col-md-4">
                    <b-card header-class="bg-transparent border-danger" class="myCard">
                      <template v-slot:header>
                        <h5 class="my-0 infotitle">Réservations annulées</h5>
                      </template>
                      <div class="float-right alignBottom">
                        <h1 class="text-info">
                          {{ activity.reservations_annulees }}
                        </h1>
                      </div>
                    </b-card>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="far fa-user"></i>
                </span>
                <span class="d-none d-sm-inline-block"><i class="fas fa-taxi mr-2"></i>Historique des courses VTC</span>
              </template>
              <div class="mt-3 custom-tabs">
                <b-tabs pills card content-class="mt-3 custom-tabs" class="custom-tabs" justified>
                  <b-tab active title="En tant que conducteur">
                    <div class="row p-2 pt-3">
                      <b-pagination align="right" v-model="currentPageTaxi" :total-rows="taxiData.length"
                        :per-page="perPageTaxi" :limit="1" first-text="◀" last-text="▶" aria-controls="tableTaxi">
                      </b-pagination>
                      <div class='table-responsive'>
                        <b-table id="tableTaxi" striped hover :items="taxiData" :fields="fieldsTaxi"
                          :per-page="perPageTaxi" :current-page="currentPageTaxi">
                          <template v-slot:cell(telephone)="row">
                            <a :href="'https://wa.me/' + (row.value.replace('+', '')).replace(/\s+/g, '')">
                              {{ row.value }}
                            </a>
                          </template>
                        </b-table>
                      </div>
                    </div>
                  </b-tab>
                  <b-tab title="En tant que passager">
                    <div class="row p-2 pt-3">
                      <b-pagination v-model="currentPageTaxiP" :total-rows="taxiDataP.length" :per-page="perPageTaxiP"
                        :limit="1" first-text="◀" last-text="▶" aria-controls="tableTaxi" align="right">
                      </b-pagination>
                      <div class='table-responsive'>
                        <b-table id="tableTaxi" striped hover :items="taxiDataP" :fields="fieldsTaxiP"
                          :per-page="perPageTaxiP" :current-page="currentPageTaxiP">
                          <template v-slot:cell(telephone)="row">
                            <a :href="'https://wa.me/' + (row.value.replace('+', '')).replace(/\s+/g, '')">
                              {{ row.value }}
                            </a>
                          </template>
                        </b-table>
                      </div>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="far fa-user"></i>
                </span>
                <span class="d-none d-sm-inline-block"><i class="fas fa-barchart mr-2"></i>Statistiques VTC</span>
              </template>
              <div class="mt-3">
                <div class="my-3 font-weight-bold font-size-2">Statistiques sur l'année</div>
                <div class="form-group d-flex ">
                  <select v-model="selectedYearStat" class="form-control w-25 mr-3">
                    <option v-for="option in yearList" :key="option.value" :value="option.value">
                      {{ option.label }}
                    </option>
                  </select>
                  <select v-model="selectedOption" class="form-control w-25">
                    <option v-for="option in optionsList" :key="option.key" :value="option.key">
                      {{ option.label }}
                    </option>
                  </select>
                </div>
                <ChartYear :height="300" :annee="parseInt(selectedYearStat)" :conducteurId="parseInt(conducteurId)"
                  :selectedOption="this.selectedOption || 1" />
              </div>
              <div class="mt-3">
                <div class="my-3 font-weight-bold font-size-2">Statistiques par mois</div>
                <div class="form-group d-flex ">
                  <select v-model="selectedYearStat" class="form-control w-25 mr-3">
                    <option v-for="option in yearList" :key="option.value" :value="option.value">
                      {{ option.label }}
                    </option>
                  </select>
                  <select v-model="selectedOption" class="form-control w-25 mr-3">
                    <option v-for="option in optionsList" :key="option.key" :value="option.key">
                      {{ option.label }}
                    </option>
                  </select>
                  <select v-model="selectedMonth" class="form-control w-25">
                    <option v-for="option in monthList" :key="option.key" :value="option.key">
                      {{ option.label }}
                    </option>
                  </select>
                </div>
                <ChartMonth :height="300" :annee="parseInt(selectedYearStat)" :conducteurId="parseInt(conducteurId)"
                  :selectedMonth='this.selectedMonth' :selectedOption="this.selectedOption || 1" />
              </div>
              <div class="mt-3">
                <div class="my-4 font-weight-bold font-size-2">Statistiques des 7 derniers jours</div>
                <div class="form-group d-flex ">
                  <select v-model="selectedOption" class="form-control w-25 mr-3">
                    <option v-for="option in optionsList" :key="option.key" :value="option.key">
                      {{ option.label }}
                    </option>
                  </select>


                </div>
                <ChartDay :height="300" :conducteurId="parseInt(conducteurId)"
                  :selectedOption="this.selectedOption || 1" />
              </div>
            </b-tab>

          </b-tabs>
        </div>
      </div>
    </div>
    <b-modal id="modal-backdrop" centered v-model="showModalprofil" title="Téléverser ce profil ?" title-class="font-18"
      hide-footer>
      <form action="">
        <input type="file" id="fileProfil" ref="fileProfil" v-on:change="handleFileProfil" class="form-control"
          placeholder="Photo de permis" />
        <div class="row float-right mt-3">
          <b-button variant="danger" @click="showModalprofil = false">Non</b-button>
          <b-button variant="success" class="ml-3 mr-4" @click="addProfil">Oui</b-button>
        </div>
      </form>
    </b-modal>
    <b-modal id="modal-backdrop" centered v-model="showModalcni" title="Téléverser cette CNI ?" title-class="font-18"
      hide-footer>
      <form action="">
        <input type="file" id="fileCni" ref="fileCni" v-on:change="handleFileCni" class="form-control"
          placeholder="Photo de permis" />
        <div class="row float-right mt-3">
          <b-button variant="danger" @click="showModalcni = false">Non</b-button>
          <b-button variant="success" class="ml-3 mr-4" @click="addCNI">Oui</b-button>
        </div>
      </form>
    </b-modal>
    <b-modal id="modal-backdrop" centered v-model="showModalpermis" title="Téléverser ce permis ?" title-class="font-18"
      hide-footer>
      <form action="">
        <input type="file" id="filePermis" ref="filePermis" v-on:change="handleFilePermis" class="form-control"
          placeholder="Photo de permis" />
        <div class="row float-right mt-3">
          <b-button variant="danger" @click="showModalpermis = false">Non</b-button>
          <b-button variant="success" class="ml-3 mr-4" @click="addPermis">Oui</b-button>
        </div>
      </form>
    </b-modal>
    <b-modal id="modal-backdrop" centered v-model="showModalautoMoto" title="Téléverser ce Auto/Moto ?"
      title-class="font-18" hide-footer>
      <form action="">
        <input type="file" id="fileMoto" ref="fileMoto" v-on:change="handleFileMoto" class="form-control"
          placeholder="Photo de permis" />
        <div class="row float-right mt-3">
          <b-button variant="danger" @click="showModalautoMoto = false">Non</b-button>
          <b-button variant="success" class="ml-3 mr-4" @click="addAutoMoto">Oui</b-button>
        </div>
      </form>
    </b-modal>
    <b-modal id="modal-backdrop" centered v-model="showModalautoMotoOtherPop" title="Téléverser ce Auto/Moto ?"
      title-class="font-18" hide-footer>
      <form action="">
        <input type="file" id="fileMoto" ref="fileMoto" v-on:change="handleFileMoto" class="form-control"
          placeholder="Photo de permis" />
        <div class="row float-right mt-3">
          <b-button variant="danger" @click="showModalautoMotoOtherPop = false">Non</b-button>
          <b-button variant="success" class="ml-3 mr-4" @click="addAutoMotoOther">Oui</b-button>
        </div>
      </form>
    </b-modal>
    <b-modal size="xl" hide-footer centered v-model="HistoriqueConducteur" title="Historique Conducteur"
      title-class="font-18">
      <b-tabs nav-class="nav-tabs-custom">
        <b-tab title-link-class="p-3">
          <template v-slot:title>
            <a class="font-weight-bold active">Historique des trajets</a>
          </template>
          <div class="row mt-4">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Afficher&nbsp;
                  <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entrées
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                <label class="d-inline-flex align-items-center">
                  Rechercher:
                  <b-form-input v-model="filter" type="search" class="form-control form-control-sm ml-2"></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>
          <div class="table-responsive">
            <b-table class="table-centered" :items="conducteurData" :fields="conducteur" responsive="sm"
              :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
              :filter="filter" :filter-included-fields="filterOn">
              <template v-slot:cell(paymentstatus)="row">
                <div class="badge font-size-12" :class="{
      'badge-soft-danger': `${row.value}` === 'Chargeback',
      'badge-soft-success': `${row.value}` === 'Paid',
      'badge-soft-warning': `${row.value}` === 'Unpaid',
    }">
                  {{ row.value }}
                </div>
              </template>
              <template v-slot:cell(invoice)>
                <button class="btn btn-light btn-rounded">
                  Invoice
                  <i class="mdi mdi-download ml-2"></i>
                </button>
              </template>
              <template v-slot:cell(action)>
                <a href="javascript:void(0);" class="mr-3 text-primary" v-b-tooltip.hover title="Edit">
                  <i class="mdi mdi-pencil font-size-18"></i>
                </a>
                <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Delete">
                  <i class="mdi mdi-trash-can font-size-18"></i>
                </a>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-right">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination v-model="currentPage" :prev-text="'Précédent'" :next-text="'suivant'"
                    :total-rows="conducteurData.length" :per-page="perPage"></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-modal>
    <b-modal size="xl" hide-footer centered v-model="HistoriquePassager" title="Historique Passager"
      title-class="font-18">
      <b-tabs nav-class="nav-tabs-custom">
        <b-tab title-link-class="p-3">
          <template v-slot:title>
            <a class="font-weight-bold active">Historique des trajets</a>
          </template>
          <div class="row mt-4">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Afficher&nbsp;
                  <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entrées
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                <label class="d-inline-flex align-items-center">
                  Rechercher:
                  <b-form-input v-model="filter" type="search" class="form-control form-control-sm ml-2"></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>
          <div class="table-responsive">
            <b-table class="table-centered" :items="passagerData" :fields="passager" responsive="sm" :per-page="perPage"
              :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
              :filter-included-fields="filterOn">
              <template v-slot:cell(paymentstatus)="row">
                <div class="badge font-size-12" :class="{
      'badge-soft-danger': `${row.value}` === 'Chargeback',
      'badge-soft-success': `${row.value}` === 'Paid',
      'badge-soft-warning': `${row.value}` === 'Unpaid',
    }">
                  {{ row.value }}
                </div>
              </template>
              <template v-slot:cell(invoice)>
                <button class="btn btn-light btn-rounded">
                  Invoice
                  <i class="mdi mdi-download ml-2"></i>
                </button>
              </template>
              <template v-slot:cell(action)>
                <a href="javascript:void(0);" class="mr-3 text-primary" v-b-tooltip.hover title="Edit">
                  <i class="mdi mdi-pencil font-size-18"></i>
                </a>
                <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Delete">
                  <i class="mdi mdi-trash-can font-size-18"></i>
                </a>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-right">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination v-model="currentPage" :prev-text="'Précédent'" :next-text="'suivant'"
                    :total-rows="passagerData.length" :per-page="perPage"></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-modal>
    <b-modal id="modal-backdrop" centered v-model="showSucessM" title="Message envoyé avec succès !!!"
      title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showSucessM = false">OK</b-button>
      </div>
    </b-modal>
    <b-modal id="modal-backdrop" centered v-model="showSucess" title="Badge attribué avec succès !!!"
      title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showSucess = false">OK</b-button>
      </div>
    </b-modal>
    <b-modal id="modal-backdrop" centered v-model="showEchecM" title="Echec d'envoie !!" title-class="font-18"
      hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showEchecM = false">Reéssayez</b-button>
      </div>
    </b-modal>
    <b-modal id="modal-backdrop" centered v-model="showretire" title="Badge retiré avec succès !!!"
      title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showSucess = false">OK</b-button>
      </div>
    </b-modal>
    <b-modal id="modal-backdrop" centered v-model="showEchec" title="Badge désactivé" title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showEchec = false">Reéssayez</b-button>
      </div>
    </b-modal>
    <!-- ENVOYER LE MESSAGE NOTIF-->
    <b-modal @shown="focusMyElement" v-model="showMessage" title="Nouveau Message Notif" centered>
      <form>
        <div class="form-group">
          <!--<label class="control-label">Choisir le type de message:</label>-->
          <multiselect v-model="messageSelect" :options="tyMessage" label="name" track-by="value" disabled>
          </multiselect>
        </div>
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Sujet" v-model="messageContent.sujet" />
        </div>
        <div class="form-group mb-2">
          <!--  <ckeditor
                v-model="messageContent.message"
                :editor="editor"
                aria-placeholder="Votre Message"
              ></ckeditor> -->
          <textarea v-model="messageContent.message" placeholder="Votre message" class="form-control" id="" cols="30"
            rows="10"></textarea>
        </div>
        <div class="form-group">
          <multiselect v-model="mediaSelect" :options="tyMedia" placeholder="Image, vidéo" label="name"
            track-by="value">
          </multiselect>
        </div>
        <div class="form-group">
          <input type="text" class="form-control" v-model="url_link" placeholder="Lien du média" />
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showMessage = false">Fermer</b-button>
        <b-button variant="primary" @click="addMessage">
          Envoyer
          <i class="fab fa-telegram-plane ml-1"></i>
        </b-button>
      </template>
    </b-modal>
    <!-- -->
    <!-- ENVOYER LE MESSAGE MAIL-->
    <b-modal v-model="showMessageMail" title="Nouveau Message Mail" centered>
      <form>
        <div class="form-group">
          <!--<label class="control-label">Choisir le type de message:</label>-->
          <multiselect v-model="messageSelect" :options="tyMessage" label="name" track-by="value" disabled>
          </multiselect>
        </div>
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Sujet" v-model="messageContent.sujet" />
        </div>
        <div class="form-group mb-2">
          <ckeditor v-model="messageContent.message" :editor="editor" aria-placeholder="Votre Message" ref="focusThis">
          </ckeditor>
        </div>
        <div class="form-group">
          <multiselect v-model="mediaSelect" :options="tyMedia" placeholder="Image, vidéo" label="name"
            track-by="value">
          </multiselect>
        </div>
        <div class="form-group">
          <input type="text" class="form-control" v-model="url_link" placeholder="Lien du média" />
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showMessage = false">Fermer</b-button>
        <b-button variant="primary" @click="addMessage">
          Envoyer
          <i class="fab fa-telegram-plane ml-1"></i>
        </b-button>
      </template>
    </b-modal>
    <!-- -->
    <!-- CHANGER LE MOT DE PASSE -->
    <b-modal v-model="showModalChangePass" title="Changer le mot de passe" centered>
      <form>
        <div class="form-group">
          <input type="password" class="form-control" placeholder="Mot de passe" v-model="newpassword" required />
        </div>
        <div class="form-group">
          <input type="password" class="form-control" placeholder="Confirmation Mot de passe" v-model="confnewpassword"
            required />
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showModalChangePass = false">Fermer</b-button>
        <b-button variant="primary" @click="changePass">
          Appliquer
          <i class="fas fa-key ml-1"></i>
        </b-button>
      </template>
    </b-modal>
    <!-- Preferences modal  -->
    <b-modal size='lg' v-model="showModalPref" title="Préférences Utilisateur" centered>
      <form>
        <div class="row">
          <div class="col-md-6">
            <!-- <div class="form-group">
              <label for="user">Utilisateur:</label>
              <input type="number" class="form-control" id="user" v-model="pref.user" required />
            </div> -->
            <div class="form-group">
              <label for="enjoyMusicInCar">Apprécier la musique en voiture:</label>
              <select class="form-control" id="enjoyMusicInCar" v-model="pref.enjoyMusicInCar">
                <option :value="pref.enjoyMusicInCar" disabled>{{ defaultOptionText(pref.enjoyMusicInCar) }}</option>
                <option value="ALWAYS">Toujours</option>
                <option value="NEVER">Jamais</option>
                <option value="DEPEND">Dépend</option>
              </select>
            </div>
            <div class="form-group">
              <label for="discussInCar">Discuter en voiture:</label>
              <select class="form-control" id="discussInCar" v-model="pref.discussInCar">
                <option :value="pref.discussInCar" disabled>{{ defaultOptionText(pref.discussInCar) }}</option>
                <option value="ALWAYS">Toujours</option>
                <option value="NEVER">Jamais</option>
                <option value="DEPEND">Dépend</option>
              </select>
            </div>
            <div class="form-group">
              <label for="smokeInCar">Fumer en voiture:</label>
              <select class="form-control" id="smokeInCar" v-model="pref.smokeInCar">
                <option :value="pref.smokeInCar" disabled>{{ defaultOptionText(pref.smokeInCar) }}</option>
                <option value="ALWAYS">Toujours</option>
                <option value="NEVER">Jamais</option>
                <option value="DEPEND">Dépend</option>
              </select>
            </div>
            <div class="form-group">
              <label for="eatInCar">Manger en voiture:</label>
              <select class="form-control" id="eatInCar" v-model="pref.eatInCar">
                <option :value="pref.eatInCar" disabled>{{ defaultOptionText(pref.eatInCar) }}</option>
                <option value="ALWAYS">Toujours</option>
                <option value="NEVER">Jamais</option>
                <option value="DEPEND">Dépend</option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="acceptPeopleDisabilities">Accepter les personnes handicapées:</label>
              <select class="form-control" id="acceptPeopleDisabilities" v-model="pref.acceptPeopleDisabilities">
                <option :value="pref.acceptPeopleDisabilities" disabled>
                  {{ defaultOptionText(pref.acceptPeopleDisabilities) }}
                </option>
                <option value="ALWAYS">Toujours</option>
                <option value="NEVER">Jamais</option>
                <option value="DEPEND">Dépend</option>
              </select>
            </div>
            <div class="form-group">
              <label for="tolerateStops">Tolérer les arrêts:</label>
              <select class="form-control" id="tolerateStops" v-model="pref.tolerateStops">
                <option :value="pref.tolerateStops" disabled>{{ defaultOptionText(pref.tolerateStops) }}</option>
                <option value="ALWAYS">Toujours</option>
                <option value="NEVER">Jamais</option>
                <option value="DEPEND">Dépend</option>
              </select>
            </div>
            <div class="form-group">
              <label for="userType">Type d'utilisateur:</label>
              <select class="form-control" id="userType" v-model="pref.userType">
                <!-- Assuming userTypes is an array of available user types -->
                <option value="CONDUCTEUR">CONDUCTEUR</option>
                <option value="PARTICULIER">PARTICULIER</option>
                <option value="TAXIMAN">TAXIMAN</option>
                <option value="SOCIETE">SOCIETE</option>
                <option value="PASSAGER">PASSAGER</option>
                <option value="TRICYCLE">TRICYCLE</option>
                <option value="ZEM">ZEM</option>
              </select>
            </div>
            <div class="form-group">
              <label for="genre">Genre:</label>
              <select class="form-control" id="genre" v-model="pref.genre" required>
                <option value="M">Masculin</option>
                <option value="F">Féminin</option>
              </select>
            </div>
          </div>
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showModalPref = false">Fermer</b-button>
        <b-button variant="primary" @click="updatePref">
          Soumettre
          <i class="fas fa-check ml-1"></i>
        </b-button>
      </template>
    </b-modal>
    <b-modal id="modal-backdrop" centered v-model="showChange" title="Mot de passe modifié avec succès !!!"
      title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showChange = false">OK</b-button>
      </div>
    </b-modal>
    <b-modal id="modal-backdrop" centered v-model="showEchecChange" title="Erreur Reéssayez" title-class="font-18"
      hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showEchecChange = false">Reéssayez</b-button>
      </div>
    </b-modal>
    <!-- -->
    <!--UPDATE AUTOMOTO -->
    <b-modal size="xl" v-model="showUpdate" title="Modifier les informations AutoMoto" centered>
      <form class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Marque</label>
            <input type="text" class="form-control" placeholder="Marque" v-model="AutoMoto.nomAuto" required />
          </div>
          <div class="form-group">
            <label>Model</label>
            <input type="text" class="form-control" placeholder="Model" v-model="AutoMoto.model" required />
          </div>
          <div class="form-group">
            <label>Motorisatrion</label>
            <input type="text" class="form-control" placeholder="Motorisatrion" v-model="AutoMoto.motorisation"
              required />
          </div>
          <div class="form-group">
            <label>Couleur</label>
            <input type="text" class="form-control" placeholder="Couleur" v-model="AutoMoto.couleur" required />
          </div>
          <div class="form-group">
            <label>Type véhicule</label>
            <select type="text" class="form-control" placeholder="Couleur" v-model="AutoMoto.type">
              <option value="ZEM">ZEM</option>
              <option value="TRICYCLE">TRICYCLE</option>
              <option value="BASIC">BASIC</option>
              <option value="CONFORT">CONFORT</option>
              <option value="VIP">VIP</option>
              <option value="ECO">ECO</option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Nombre de sièges</label>
            <input type="number" class="form-control" placeholder="Nombre de sièges" v-model="AutoMoto.nombresiege"
              required />
          </div>
          <div class="form-group">
            <label>Plaque d'immatriculation</label>
            <input type="text" class="form-control" placeholder="Plaque d'immatriculation"
              v-model="AutoMoto.immatriculation" required />
          </div>
          <div class="form-group">
            <label>Année</label>
            <input type="number" class="form-control" placeholder="Année" v-model="AutoMoto.anneeCirculation"
              required />
          </div>
          <div>
            <div class="clim">
              <label class="labPaque">Climatisation</label>
              <input type="checkbox" class="form-control" value="AutoMoto.clime" v-model="AutoMoto.clime" />
            </div>
          </div>
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showUpdate = false">Fermer</b-button>
        <b-button variant="primary" @click="changeAutomoto(vehiculeId)">
          Modifier
          <i class="fas fa-key ml-1"></i>
        </b-button>
      </template>
    </b-modal>
    <!-- New Auto Moto -->
    <b-modal size="xl" v-model="addNewVehicle" title="Ajouter Auto/Moto" centered>
      <form class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Marque</label>
            <input type="text" class="form-control" placeholder="Marque" v-model="AutoMoto.nomAuto" required />
          </div>
          <div class="form-group">
            <label>Model</label>
            <input type="text" class="form-control" placeholder="Model" v-model="AutoMoto.model" required />
          </div>
          <div class="form-group">
            <label>Motorisatrion</label>
            <input type="text" class="form-control" placeholder="Motorisatrion" v-model="AutoMoto.motorisation"
              required />
          </div>
          <div class="form-group">
            <label>Couleur</label>
            <input type="text" class="form-control" placeholder="Couleur" v-model="AutoMoto.couleur" required />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Nombre de sièges</label>
            <input type="number" class="form-control" placeholder="Nombre de sièges" v-model="AutoMoto.nombresiege"
              required />
          </div>
          <div class="form-group">
            <label>Plaque d'immatriculation</label>
            <input type="text" class="form-control" placeholder="Plaque d'immatriculation"
              v-model="AutoMoto.immatriculation" required />
          </div>
          <div class="form-group">
            <label>Année</label>
            <input type="number" class="form-control" placeholder="Année" v-model="AutoMoto.anneeCirculation"
              required />
          </div>
          <div>
            <div class="clim">
              <label class="labPaque">Climatisation</label>
              <input type="checkbox" class="form-control" value="AutoMoto.clime" v-model="AutoMoto.clime" />
            </div>
          </div>
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="addNewVehicle = false">Fermer</b-button>
        <b-button variant="primary" @click="addNewVehicleOrBike()">
          Ajouter
          <i class="fas fa-key ml-1"></i>
        </b-button>
      </template>
    </b-modal>
    <!-- New AUTO MOTO  END-->
    <!--UPDATE USERNAME -->
    <b-modal v-model="showModalChangeName" title="Changer les informations" centered>
      <form>
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Nom" v-model="lastname" required />
        </div>
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Prénoms" v-model="firstname" required />
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showModalChangeName = false">Fermer</b-button>
        <b-button variant="primary" @click="changeName">
          Appliquer
          <i class="fas fa-key ml-1"></i>
        </b-button>
      </template>
    </b-modal>
    <!-- Personne urgence-->
    <b-modal v-model="showModalChangeUrgence" title="Changer les informations" centered>
      <form>
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Nom" v-model="user.personneUrgence" required />
        </div>
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Indicatif Tel" v-model="user.indicatifContactTel"
            required />
        </div>
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Téléphone" v-model="user.contactUrgence" required />
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showModalChangeName = false">Fermer</b-button>
        <b-button variant="primary" @click="changePersoneUrgence">
          Appliquer
          <i class="fas fa-key ml-1"></i>
        </b-button>
      </template>
    </b-modal>
    <!--UPDATE TELEPHONE -->
    <b-modal v-model="showModalChangeTel" title="Changer les informations" centered>
      <form>
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Indicatif" v-model="indicatif" required />
        </div>
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Téléphone" v-model="tel" required />
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showModalChangeTel = false">Fermer</b-button>
        <b-button variant="primary" @click="changeTel">
          Appliquer
          <i class="fas fa-key ml-1"></i>
        </b-button>
      </template>
    </b-modal>
    <b-modal v-model="showModalChangeEmail" title="Changer les informations" centered>
      <form>
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Adresse email" v-model="user.email" required />
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showModalChangeEmail = false">Fermer</b-button>
        <b-button variant="primary" @click="changeEmail">
          Appliquer
          <i class="fas fa-key ml-1"></i>
        </b-button>
      </template>
    </b-modal>
    <b-modal v-model="showModalChangeLieuTravail" title="Changer les informations" centered>
      <form>
        <div class="form-group">
          <gmap-autocomplete :value="user.villeHabite" type="text" class="form-control" id="depart"
            placeholder="Ville de résidence" @place_changed="setDepartContry" :select-first-on-enter="true"
            style="height: 50px">
          </gmap-autocomplete>
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showModalChangeLieuTravail = false">Fermer</b-button>
        <b-button variant="primary" @click="changeLieuTravail">
          Appliquer
          <i class="fas fa-key ml-1"></i>
        </b-button>
      </template>
    </b-modal>
    <b-modal v-model="showModalChangeBday" title="Changer la date de naissance" centered>
      <form>
        <div class="form-group">
          <input type="date" class="form-control" placeholder="À propos" v-model="user.dateNaissance" required />
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showModalChangeBday = false">Fermer</b-button>
        <b-button variant="primary" @click="changeBday">
          Appliquer
          <i class="fas fa-key ml-1"></i>
        </b-button>
      </template>
    </b-modal>
    <b-modal v-model="showModalChangeApropo" title="Changer les informations" centered>
      <form>
        <div class="form-group">
          <input type="text" class="form-control" placeholder="À propos" v-model="user.apropos" required />
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showModalChangeApropo = false">Fermer</b-button>
        <b-button variant="primary" @click="changeApropo">
          Appliquer
          <i class="fas fa-key ml-1"></i>
        </b-button>
      </template>
    </b-modal>
    <b-modal v-model="showModalWorkspace" title="Changer les informations" centered>
      <form>
        <div class="form-group">
          <gmap-autocomplete :value="user.villeresidence" type="text" class="form-control" id="depart"
            placeholder="Lieu de travail" @place_changed="setDepartContryWorkPlace" :select-first-on-enter="true"
            style="height: 50px">
          </gmap-autocomplete>
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showModalWorkspace = false">Fermer</b-button>
        <b-button variant="primary" @click="changeWorkspace">
          Appliquer
          <i class="fas fa-key ml-1"></i>
        </b-button>
      </template>
    </b-modal>
    <!-- <b-modal v-model="showMessageWhatsapp" title="Nouveau Message " centered>
      <form>
        <div class="form-group mt-2">
          <input type="text" v-model="sujet_message" class="form-control" placeholder="Sujet" />
        </div>
        <div class="form-group">
          <textarea class="form-control" v-model="message" name="" id="" rows="10"></textarea>
          <multiselect v-model="mediaSelect" :options="tyMedia" placeholder="Image, vidéo" label="name"
            track-by="value">
          </multiselect>
          <input type="text" v-model="url_link" class="form-control mt-2" placeholder="Lien du média" />
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showMessageWhatsapp = false">Fermer</b-button>
        <b-button variant="primary" @click="sendMessageWhatsapp">
          Envoyerr
          <i class="fab fa-telegram-plane ml-1"></i>
        </b-button>
      </template>
    </b-modal>
    <b-modal id="modal-backdrop" centered v-model="showSendMessage" title="Confirmez-vous l'envoi du message"
      title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showSendMessage = false">Annuler</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="sendMessageWhatsapp">Valider</b-button>
      </div>
    </b-modal> -->
    <!-- CREDITER -->
    <b-modal v-model="showModalCredit" title="Créditer le wallet" centered>
      <form>
        <div class="form-group">
          <input type="number" class="form-control" v-model="montantToCredit" required />
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showModalCredit = false">Fermer</b-button>
        <b-button variant="primary" @click="credit">
          Appliquer
          <i class="fas fa-key ml-1"></i>
        </b-button>
      </template>
    </b-modal>
    <!-- RETIRER -->
    <b-modal v-model="showModalRetrait" title="Retirer du wallet" centered>
      <form>
        <div class="form-group">
          <input type="number" class="form-control" v-model="montantToCredit" required />
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showModalRetrait = false">Fermer</b-button>
        <b-button variant="primary" @click="retrait">
          Appliquer
          <i class="fas fa-key ml-1"></i>
        </b-button>
      </template>
    </b-modal>
  </Layout>
</template>
<style>
label {
  font-size: 15px;
}

.bg-gray span a i {
  color: #FFFFFF !important;
}

.divider {
  width: 100%;
  border: solid 2px blue;
}

.blueG {
  border: solid 1px #8608b8ad;
}

.YelG {
  border: solid 1px #ff6545;
}

.text-blueG {
  color: #8608b8ad;
}

.clim {
  position: absolute;
  float: left;
}

.labPaque {
  display: inline-block;
}

.checkclim {
  display: flex;
  font-size: 0.9rem;
}

/*info user style */
.firstColonne {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.otherColonne {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.div {
  clip-path: circle();
}

.imageProfile {
  border-radius: 15px;
  box-shadow: 0 15px 50px rgb(0, 0, 0, 0.35);
  width: 160px;
  height: 160px;
}

.imageProfile:hover {
  height: 200px;
  width: 200px;
}

.imageContainer img {
  display: table-cell;
  vertical-align: middle;
  transition: all ease 0.3s;
}

.flexibleLine {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.textFlexible {
  width: 200px;
  word-wrap: break-word;
}

.textFlexible p {
  display: table-cell;
  vertical-align: middle;
}

.otherBtn {
  margin: 0 auto;
}

.otherBtn button {
  width: 50px;
}

.buttonList {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-self: center;
}

.buttonList div button {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .buttonList {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 30px;
    margin: 0 auto !important;
    width: 100%;
    place-item: center;
  }

  .otherBtn {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
  }

  .buttonList button {
    width: 90%;
    margin: 0 auto;
  }

  .buttonList button.supprBtn {
    display: flex;
    align-items: flex-start;
    float: left;
  }
}

.modal-dialog {
  box-shadow: none !important;
  border-radius: 0.25rem;
}

.iconForm {
  font-weight: 700;
  font-size: 1.1rem;
}

.flexibleStart {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imageContainer img:hover {
  transform: rotate(360deg);
}

.flexibleLine:hover img {
  transform: rotate(360deg);
}

/*info du User*/
.containerInfoProfile {
  max-width: 1280px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.cards {
  position: relative;
  width: 300px;
  height: 400px;
  background: linear-gradient(#2196f3, #2196f3 30%, #252b3b 30%, #252b3b);
  margin: 10px;
  border-radius: 20px;
  overflow: hidden;
}

.containerInfoProfiles {
  max-width: 1280px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.containerInfoProfiles .cards {
  position: relative;
  width: 300px;
  height: 400px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: #fff;
  background: linear-gradient(#252b3b, #252b3b 30%, #ffffff 30%, #2196f3);
  margin: 10px;
  border-radius: 20px;
  overflow: hidden;
}

.cards {
  position: relative;
  width: 300px;
  height: 400px;
  background: linear-gradient(#2196f3, #2196f3 30%, #252b3b 30%, #252b3b);
  margin: 10px;
  border-radius: 20px;
  overflow: hidden;
}

.containerInfoProfile .cards .imgBx {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.5s;
  z-index: 10;
  transform-origin: top;
  border-radius: 20px;
  overflow: hidden;
}

.containerInfoProfiles .cards .imgBx {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.5s;
  z-index: 10;
  transform-origin: top;
  border-radius: 20px;
  overflow: hidden;
}

.containerInfoProfile .cards:hover .imgBx {
  transform: translateY(30px) scale(0.5);
}

.containerInfoProfiles .cards:hover .imgBx {
  transform: translateY(30px) scale(0.5);
}

.containerInfoProfile .cards .imgBx img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.containerInfoProfiles .cards .imgBx img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: fill;
  cursor: pointer;
}

.containerInfoProfile .cards .contentProfile {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 27%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 20px;
  z-index: 80;
  transition: 0.5s;
  background: #252b3b;
}

.containerInfoProfiles .cards .contentProfile {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 27%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 20px;
  z-index: 80;
  transition: 0.5s;
  background: #252b3b;
}

.containerInfoProfile .cards:hover .contentProfile {
  transform: translateY(0);
}

.containerInfoProfiles .cards:hover .contentProfile {
  transform: translateY(0);
}

.containerInfoProfile .cards .contentProfile .details {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.containerInfoProfiles .cards .contentProfile .details {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.containerInfoProfile .cards .contentProfile .details h2 {
  display: inline-block;
  background: #252b3b;
  color: #fff;
  font-size: 1.5em;
  font-weight: 500;
  padding: 8px;
  border-radius: 6px;
}

.containerInfoProfiles .cards .contentProfile .details h2 {
  display: inline-block;
  background: #252b3b;
  color: #fff;
  font-size: 1.5em;
  font-weight: 500;
  padding: 8px;
  border-radius: 6px;
}

.containerInfoProfiles .cards .contentProfile .details .social_icons {
  position: relative;
  display: flex;
  margin-top: 5px;
  padding-left: 0;
}

.containerInfoProfiles .cards .contentProfile .details .social_icons li {
  list-style: none;
  margin: 4px;
  text-align: center;
}

.containerInfoProfiles .cards .contentProfile .details .social_icons li i {
  list-style: none;
  margin: auto;
}

.social_icons {
  position: relative;
  display: flex;
  margin-top: 5px;
  padding-left: 0;
}

.social_icons li {
  list-style: none;
  margin: 4px;
}

.social_icons li {
  list-style: none;
  margin: 4px;
}

.social_icons li a {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  background: #294d69;
  border-radius: 50%;
  font-size: 1.5em;
  color: #fff;
  text-decoration: none;
  transition: 0.5s;
  cursor: pointer;
}

.social_icons li:first-child a {
  background: rgb(30, 144, 255);
}

.social_icons li:nth-child(2) a {
  background: red;
}

.social_icons li:nth-child(3) a {
  background: rgb(36, 188, 36);
}

.social_icons li:nth-child(4) a {
  background: #ff8c00;
}

.social_icons li i {
  width: 100%;
  margin: auto 0;
}

.social_icons li a:hover {
  background: #03a9f4;
}

.textTitle {
  line-height: 35px;
  font-weight: 700;
  font-size: 1.8em;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff;
  background: #252b3b;
}

.custom-tabs .nav-pills .nav-link.active,
.custom-tabs .nav-pills .show>.nav-link {
  color: #fff;
  background: #252b3b !important;
}

/*Dasbhoard Auto moto*/
.myCard {
  background: #ffffff;
  padding: 0.385rem;
  box-shadow: 2px 2px 3px #2c3333;
  border: 0 !important;
  height: 10rem;
  transition: ease 0.3s;
}

.myCard:hover {
  background: transparent;
  padding: 0.385rem;
  border: none;
  outline: none;
  border: 0 !important;
  height: 10rem;
  backdrop-filter: blur(15px);
  box-shadow: -10px -10px 15px rgba(255, 255, 255, 0.5),
    10px 10px 15px rgba(70, 70, 70, 0.12),
    inset -10px -10px 15px rgba(255, 255, 255, 0.5),
    inset 10px 10px 15px rgba(0, 0, 0, 0.12);
}

.alignBottom {
  position: absolute;
  bottom: 1.2rem;
  right: 0;
  padding-right: 1.5rem;
}

.nav-tabs .nav-link {
  background: #dc143c;
  transition: all 0.5s;
}

.nav-tabs .nav-link a {
  color: #fff;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff;
  background-color: #3aaa35;
}

.nav-tabs-custom .nav-item .nav-link.active a {
  color: #fff !important;
  font-weight: 600 !important;
  vertical-align: middle;
}

.nav-tabs-custom .nav-item .nav-link.active {
  font-weight: 600;
  background: #3aaa35 !important;
  border-bottom: 1px solid #000000 !important;
}

.theTitle {
  font-size: 16px;
  display: inline-block;
  font-weight: 700;
  text-align: left;
  color: #222;
}

@media screen and (max-width: 773px) {
  .cardCENTER {
    text-align: center;
  }

  .flexibleAtitude {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
  }

  .flexibleC {
    display: flex;
    justify-content: space-evenly;
  }

  .btnFlex {
    display: flex;
  }
}

.ck.ck-balloon-panel {
  z-index: 1050 !important;
}

/** New infor profile Card */
.username {
  font-size: 22px;
  text-align: center;
  font-family: Raleway, serif;
  font-weight: Bold;
  color: #0b0909;
  line-height: 35.5px;
  margin-top: 2rem;
}

.user-grade {
  font-size: 16px;
  text-align: center;
  font-family: Raleway, serif;
  font-weight: 500;
  color: rgba(11, 9, 9, 0.5);
  line-height: 30px;
}

.text-color-profil {
  color: #1877f2;
  font-weight: 700;
  font-size: 1.4rem;
  margin-left: 0.4rem;
}

.container-detail-profi {
  width: 300px;
  height: auto;
  background: rgba(242, 245, 249, 1);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding: 18px;
}

.card-adresse {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-adresse .detail {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  flex: 100px 1;
  text-align: start;
}

.card-adresse .icon {
  width: 60px;
  height: 60px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
}

.icon .fa-mobile {
  font-size: 1.8rem;
  color: #ec1c09;
}

.icon .fa-envelope-open,
.fa-calendar {
  font-size: 1.8rem;
  color: #ff9a1a;
}

.icon .fa-user-tag,
.fa-house-laptop {
  font-size: 1.8rem;
  color: #ec1c09;
}

.icon .fa-star,
.fa-address-card {
  font-size: 1.8rem;
  color: #ec1c09;
}

.icon .fa-signal,
.fa-user-nurse,
.fa-city {
  font-size: 1.8rem;
  color: #ff9a1a;
}

.card-adresse .detail:not(.telspace) div {
  margin-left: 7px;
  margin-top: 0px;
}

.user-tel-title {
  color: rgba(26, 16, 3, 0.5);
  font-size: 14px;
  font-family: Raleway, serif;
  font-weight: 500;
}

.user-tel-phone {
  color: #000000;
  font-size: 14px;
  font-family: Raleway, serif;
  font-weight: 600;
}

.link-color-whatsap {
  color: #dc143c;
  font-size: 14px;
  font-family: Raleway, serif;
  font-weight: 600;
  text-decoration: underline;
}

.vue-star-rating-star[data-v-fde73a0c] {
  height: 20px;
  margin-left: -5px;
}

.flexInself {
  align-self: flex-end;
}

.pac-container {
  z-index: 10000 !important;
}

.flexibleImage {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: auto;
}

.textTitle::before {
  content: " ";
  display: inline-block;
  width: 50px;
  height: 5.5px;
  background: #3aaa35;
  margin-right: 2rem;
}

.textTitle::after {
  content: " ";
  display: inline-block;
  width: 50px;
  height: 5.5px;
  background: #3aaa35;
  margin-left: 2rem;
}

.r-bg-color {
  background: #3aaa35;
  color: #fff;
}

.border-rcolis {
  border-color: #3aaa35 !important;
}

.formImage img {
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 10rem;
}

.marginImage {
  margin-bottom: 1.2rem;
}

.flexible-gain-status {
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-wrap: wrap;
}

.gain-status {
  margin: 23px;
  padding: 10px;
  border: 3px inset #3aaa35;
  background: rgba(242, 245, 249, 1);
  border-radius: 10px;
  width: 150px;
  height: 5.5rem;
}

.gain-status h3 {
  font-size: 17px;
  font-weight: 500;
  line-height: 23px;
  white-space: nowrap;
}

.gain-status p {
  font-size: 17px;
  font-weight: 700;
  line-height: 40px;
  margin-top: 10px;
  color: #000000;
}

.margiN {
  margin-left: 3.6rem;
}

.margin-pref {
  margin-top: 2.5rem;
}
</style>
