<script>
import { Bar } from "vue-chartjs";
import { apiRequest } from "@/helpers/api-call";

export default {
  props: {
    annee: {
      type: Number,
      default: null,
    },
    conducteurId: {
      type: Number,
      default: null,
    },

    selectedOption: {
      type: Number,
      default: 1,
    }
  },
  extends: Bar,
  data() {
    return {
      dataGain: null,
      dataRecette: [],
      option: {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
        8: [],
        9: [],
        10: [],
      },

      mois: [
        "Jan",
        "Fév",
        "Mar",
        "Avr",
        "Mai",
        "Jui",
        "Juil",
        "Août",
        "Sept",
        "Oct",
        "Nov",
        "Déc",
      ],
      dataMois: [],
      dataToLoad: null,
    }
  },
  watch: {
    annee: {
      handler() {
        this.$nextTick(() => {
        this.loadData();
      });
      },
    },
    selectedOption: {
      handler() {
        this.$nextTick(() => {
        this.loadData();
      });
      },
    },
  },
  async mounted() {
    //console.log("this.annee:", this.annee);

    await this.loadData();
  },
  methods: {


    getArrayByKey(key) {
      return this.option[key] || [];
    },

    async loadData() {
      //console.log("ca cillet de bus  anee", this.annee)
      this.dataRecette = [];
      this.dataMois = [];
      for (let i = 1; i <= 10; i++) {
    this.$set(this.option, i, []);
  }

      const response = await apiRequest(
        "GET",
        `info-driver-annee/courses?annee=${this.annee}&conducteurId=${this.conducteurId}`
      );

      //console.log("stat annuel", response, this.conducteurId)

      //console.log("option", this.selectedOption, "conshucteur id", this.conducteurId)
     

      if (response && response.status == 200) {
        var statData = response.data;
        this.dataRecette = []
        this.dataMois = []
        

        await statData.stat_months.forEach((el) => {
          //console.log('element', el)
          this.option[1].push(el.changement_revenu);
          this.option[2].push(el.demande_accepte);
          this.option[3].push(el.distance_total);
          this.option[4].push(el.pourcentage_courses_a_heure);
          this.option[5].push(el.taux_acceptation);
          this.option[6].push(el.totalOnlineTimeHours);
          this.option[7].push(el.total_courses_effectuees);
          this.option[8].push(el.revenus);
          this.option[9].push(el.revenu_moyen_par_course);
          this.option[10].push(el.nbr_demande);

          

          this.dataMois.push(this.mois[el.mois - 1]);
        });

        //console.log('this option', this.option)

        this.dataToLoad =this.getArrayByKey(this.selectedOption)

        this.renderChart(
          {
            labels: this.dataMois,
            datasets: [
              {
                label: "Stats Annuelles",
                barPercentage: 0.4,
                backgroundColor: "#3AAA35 ",
                borderColor: "#3AAA35 ",
                borderWidth: 2,
                hoverBackgroundColor: "#3AAA35 ",
                hoverBorderColor: "#3AAA35 ",
                data: this.dataToLoad , //[65, 59, 81, 45, 56, 80, 50, 20]
              },
            ],
          },
          {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              xAxes: [
                {
                  gridLines: {
                    color: "rgba(166, 176, 207, 0.1)",
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: {
                    color: "rgba(166, 176, 207, 0.1)",
                  },
                },
              ],
            },
          }
        );

        // this.$emit("totalCACourse", response.data.total_ca_annee);

      }
    },
  },
}
</script>
